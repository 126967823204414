/*
 * Client-Server JSON helper.
 * Создан по мотивам Google JSON style guide.
 */

import { isArray, isError, isNil } from 'lodash-es';
import { VError } from './verror';

export interface ICSJResponseErrorItem {
    domain?: string;
    reason?: string;
    message?: string;
    location?: string;
    locationType?: string;
    extendedHelp?: string;
    sendReport?: string;
}

export interface ICSJResponseError {
    code?: number;
    name?: string;
    message: string;
    errors?: Array<ICSJResponseErrorItem>;
}

export interface ICSJRequestData<T> {
    kind?: string;
    fields?: string;
    etag?: string;
    id?: string;
    lang?: string;
    updated?: string;
    deleted?: boolean;
    itemsPerPage?: number;
    startIndex?: number;
    pageIndex?: number;
    sortField?: string;
    sortOrder?: string;
    filter?: {
        [key: string]: string | number;
    };
    items?: Array<T>;
    [key: string]: any;
}

export interface ICSJResponseData<T> {
    kind?: string;
    fields?: string;
    etag?: string;
    id?: string;
    lang?: string;
    updated?: string;
    deleted?: boolean;
    currentItemCount?: number;
    itemsPerPage?: number;
    startIndex?: number;
    totalItems?: number;
    pageIndex?: number;
    totalPages?: number;
    items?: Array<T>;
    [key: string]: any;
}

export interface ICSJMessage<T> {
    apiVersion?: string;
    context?: string;
    id?: string;
    method?: string;
    params?: {
        [key: string]: any;
    };
    data?: ICSJResponseData<T> | ICSJRequestData<T>;
    error?: ICSJResponseError;
}

function messageData<T>(
    items: T,
    options?: ICSJRequestData<T> | ICSJResponseData<T>
): ICSJRequestData<T> | ICSJResponseData<T> {
    const csj: ICSJMessage<T> = {
        ...options,
    };
    if (isError(items)) {
        csj.error = {
            name: items.name,
            message: items.message,
        };
    } else {
        csj.data = {
            items: isArray(items) ? [...items] : [{ ...items }],
        };
    }
    return csj;
}

/**
 * @description Укладывает данные Запроса в структуру.
 */
function buildRequest<T>(method: string, params: any, messageData?: ICSJRequestData<T>): ICSJMessage<T> {
    const csj: ICSJMessage<T> = {
        method: method ?? '',
        params: params ?? {},
        data: {
            ...messageData,
        },
    };
    return csj;
}

/**
 * Проверяем то, что предъявляемцй объект может быть запросом CSJ
 */
function isCSJMessage(obj: any): obj is ICSJMessage<unknown> {
    return 'method' in obj && 'params' in obj && 'data' in obj;
}

/**
 * @description Укладывает данные в структуру.
 * Различает Error в качестве параметра.
 * Можно передать VError с info = { errors: [<IResponseErrorItem>] }. Этот массив также будет уложен в сообщение и позже будет доступен по VError.info().
 */
function build<T>(items: T, options?: ICSJRequestData<T> | ICSJResponseData<T>): ICSJMessage<T> {
    const csjMessage: ICSJMessage<T> = {};
    if (isError(items)) {
        csjMessage.error = {
            name: 'Error',
            message: items.message,
            errors: [],
        };
        const errorInfo: Array<ICSJResponseErrorItem> = VError.info(items)?.errors;
        if (errorInfo && isArray(errorInfo)) {
            errorInfo.forEach((extraError) => {
                const err: ICSJResponseErrorItem = {
                    domain: extraError.domain,
                    message: extraError.message,
                    ...(extraError.reason ? { reason: extraError.reason } : {}),
                    ...(extraError.location ? { location: extraError.location } : {}),
                    ...(extraError.locationType ? { location: extraError.locationType } : {}),
                    ...(extraError.extendedHelp ? { location: extraError.extendedHelp } : {}),
                    ...(extraError.sendReport ? { location: extraError.sendReport } : {}),
                };
                csjMessage.error?.errors?.push(err);
            });
        }
    } else {
        csjMessage.data = {
            ...options,
            items: isArray(items) ? [...items] : [{ ...items }],
        };
    }
    return csjMessage;
}

/**
 * @description Проверяет есть ли в сообщении данные.
 */
function isEmpty(csj?: ICSJMessage<any>): boolean {
    return (csj?.data?.items ?? []).length === 0;
}

/**
 * @description Проверяет есть ли в сообщении данные.
 */
function hasData(csj?: ICSJMessage<any>): boolean {
    return !isEmpty(csj?.data);
}

/**
 * @description Проверяет сообщение на наличие записанной в нем ошибки.
 * В случае отсутствия ошибки сообщение считается успешным безотносительно того,
 * имеются ли в сообщении другие передаваемые полезные данные.
 */
function isSuccess<T>(csj: ICSJMessage<T>): boolean {
    return isNil(csj.error);
}

/**
 * @description Проверяет сообщение на наличие записанной в нем ошибки.
 * Если есть ошибка, то будет возвращено true.
 */
function isFailure<T>(csj?: ICSJMessage<T>): boolean {
    return !isNil(csj?.error);
}

/**
 * @description Достает ошибку из структуры CSJ в виде VError.
 * Возвращает новый VError или undefined, если ошибки не было.
 */
function getError(csj?: ICSJMessage<any>): Error | undefined {
    if (isFailure(csj)) {
        const verrorInfo: any = {};
        if (csj?.error?.errors?.length) {
            csj?.error?.errors.forEach((errItem) => {
                verrorInfo[errItem.domain ?? 0] = errItem.message;
            });
        }
        return new VError(
            {
                name: csj?.error?.name ?? 'Error',
                info: { errors: csj?.error?.errors },
            },
            csj?.error?.message ?? 'General error'
        );
    }
    return;
}

/**
 * @description Достает полные данные из структуры CSJ.
 */
function getData(csj?: ICSJMessage<any>): Error | ICSJRequestData<any> | ICSJResponseData<any> | undefined {
    // TODO: возвращать только определенные в интерфейсах поля
    return getError(csj) ?? csj?.data;
}

/**
 * @description Достает данные из структуры CSJ.
 * Возвращает новый VError или данные в виде массива.
 */
function getItems(csj?: ICSJMessage<any>): Error | Array<any> | undefined {
    return getError(csj) ?? csj?.data?.items;
}

/**
 * @description Достает чистые данные из структуры CSJ.
 * Возвращает элемент из data.items, по умолчанию - нулевой.
 */
function getItem(csj?: ICSJMessage<any>, itemNumber = 0): any | undefined {
    return getError(csj) ?? csj?.data?.items?.[itemNumber];
}

/**
 *
 */
class CSJMessage implements ICSJMessage<any> {
    apiVersion?: string;

    context?: string;

    id?: string;

    method?: string;

    params?: {
        id?: string;
    };

    data?: ICSJResponseData<any> | ICSJRequestData<any>;

    error?: ICSJResponseError;

    constructor(items?: any) {
        if (isError(items)) {
            this.error = {
                name: items.name,
                message: items.message,
            };
        } else {
            this.data = {
                items: isArray(items) ? [...items] : [{ ...items }],
            };
        }
    }

    isSuccess(): boolean {
        return isNil(this.error);
    }

    hasError(): boolean {
        return !isNil(this.error);
    }

    hasItems(): boolean {
        return !this.isEmpty();
    }

    isEmpty(): boolean {
        return (this.data?.items ?? []).length === 0;
    }

    item0(): any {
        return this.data?.items?.[0];
    }

    fromJSON(jsonString: string): CSJMessage {
        const json = JSON.parse(jsonString);
        this.apiVersion = json?.apiVersion;
        this.context = json?.context;
        this.id = json?.id;
        this.params = json?.params;
        this.error = json?.error;
        this.data = json?.data;

        return this;
    }

    toJson(): string {
        return JSON.stringify(this);
    }
}

export const CSJ = {
    build,
    messageData,
    buildRequest,
    buildResponse: build,
    getFrom: getItems,
    getItem,
    getItems,
    getData,
    isCSJMessage,
    isFailure,
    isSuccess,
    isEmpty,
    hasData,
    CSJMessage,
};

export default CSJ;
