/* eslint-disable jsx-a11y/media-has-caption */
import { attachSpeakers } from '../../lib/media/attachSpeakers';
import { isNil } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { VideoPlayerControllerContext } from './VideoPlayerControllerContext';
import { VideoPlayerEventPublicatorContext } from './VideoPlayerEventPublicatorContext';
import { VideoPlayerContext } from './VideoPlayerContext';

try {
    Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
        get() {
            return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
        },
    });
    // eslint-disable-next-line no-empty
} catch (e) {} // Will catch "property already defined" on hot reload

export type VideoPlayerVideoElement = HTMLVideoElement & {
    playing: boolean;
};

export const VideoPlayerVideo: React.FC = () => {
    const { speakerDeviceId, cachedVideoStatus, cachedVideoTimeMark, cachedVideoIsMuted } = useContext(
        VideoPlayerControllerContext
    );

    const {
        sources,
        videoRef,
        videoElementId,
        videoStartTime = 0,
        videoIsLooping: loop,
        videoIsMuted: muted,
        videoAutoplay,
    } = useContext(VideoPlayerContext);

    const mediaRef = videoRef;

    const { publishPlay, publishPause } = useContext(VideoPlayerEventPublicatorContext);

    useEffect(() => {
        if (mediaRef?.current) {
            attachSpeakers(mediaRef.current, speakerDeviceId);
        }
    }, [speakerDeviceId]);

    useEffect(() => {
        if (mediaRef?.current) {
            if (cachedVideoStatus === 'PLAYING' && !mediaRef.current.playing) {
                mediaRef.current.play();
            }
            if (cachedVideoStatus !== 'PLAYING' && mediaRef.current.playing) {
                mediaRef.current.pause();
            }
        }
    }, [cachedVideoStatus]);

    useEffect(() => {
        if (mediaRef?.current) {
            if (!isNil(cachedVideoTimeMark)) {
                console.log('VP useEffect: set cachedVideoTimeMark', cachedVideoTimeMark);
                mediaRef.current.currentTime = cachedVideoTimeMark;
            }
        }
    }, [cachedVideoTimeMark]);

    useEffect(() => {
        if (mediaRef?.current) {
            if (cachedVideoTimeMark || videoStartTime) {
                mediaRef.current.currentTime = cachedVideoTimeMark ?? videoStartTime;
            }
        }
    });

    function playPause(event) {
        event.stopPropagation();
        const mediaElement = mediaRef?.current;
        if (mediaElement) {
            if (mediaElement.playing) {
                mediaElement.pause();
                publishPause(mediaElement.currentTime);
            } else {
                mediaElement.loop = loop;
                mediaElement.play();
                publishPlay(mediaElement.currentTime);
            }
        }
    }

    return (
        <video
            key={videoElementId}
            ref={mediaRef}
            id={videoElementId}
            slot="media"
            preload="auto"
            muted={cachedVideoIsMuted ?? muted}
            crossOrigin=""
            playsInline
            autoPlay={videoAutoplay && (isNil(cachedVideoStatus) || cachedVideoStatus === 'PLAYING')}
            loop={loop}
            controls={false}
            disablePictureInPicture
            controlsList="nodownload, nofullscreen, noremoteplayback, noplaybackrate"
            style={{ width: '100%', height: '100%' }}
            onClick={playPause}>
            {sources.map((source, index) => (
                <source
                    key={`vid-src-${source.mimetype}-${index}`}
                    id={`${videoElementId}-src-${source.mimetype}-${index}`}
                    src={source.url}
                    type={source.mimetype}
                />
            ))}
            <p>Your browser does not support HTML5 video.</p>
        </video>
    );
};
