import React from 'react';

interface IRoomExpositionItemContext {
    itemId: string | null;
    itemType: string | null;

}
export const RoomExpositionItemContext = React.createContext<IRoomExpositionItemContext>({
    itemId: null,
    itemType: null,
});
export const RoomExpositionItemMouseContext = React.createContext({
    mouseIsOver: true,
});
