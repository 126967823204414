import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '@tellsla/config';

function Copyright() {
    const { t } = useTranslation();
    return (
        <Typography variant="body2" color="textSecondary" align="center" justifyItems="center">
            {'© '}
            <Link color="inherit" href={config.logoutPath}>
                {t('VILTime')}
            </Link>
            {` ${new Date().getFullYear()}.`}
        </Typography>
    );
}

export default Copyright;
