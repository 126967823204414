import { Box, CircularProgress, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';

import { theme } from '@tellsla/ui-run';

import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';

import { NotificationListener } from '@tellsla/ui-run';

import LandingHome from './components/landing/LandingHomePage';
import RegisterUser from './components/landing/pages/RegisterUser';
import { TariffInfoBoard } from './components/landing/pages/Tariff/TariffInfoBoard';

function App() {
    console.log('VILTime Landing');

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense
                fallback={
                    <Box
                        sx={{
                            height: '100vh',
                            width: '100vw',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress size={64} />
                    </Box>
                }
            >
                <BrowserRouter>
                    <Routes>
                        <Route index element={<LandingHome />} />
                        <Route path="/register" element={<RegisterUser />} />
                        <Route path="/tariffs" element={<TariffInfoBoard />} />
                        <Route path="*" element={<Navigate replace to="/" />} />
                    </Routes>
                    <NotificationListener />
                </BrowserRouter>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;
