import { ILogRecord } from '@tellsla/serverTypes';
import merge from 'lodash/merge';
import { ILogObj, Logger as TsLogger } from 'tslog';

export type TLogger = {
    Trace: (...args: any[]) => void;
    Log: (...args: any[]) => void;
    Warn: (...args: any[]) => void;
    Debug: (...args: any[]) => void;
    Err: (...args: any[]) => void;
    logger: TsLogger<ILogObj>;
};

const rootNativeLogger = new TsLogger<ILogObj>({
    // hideLogPositionForProduction: true,
    stylePrettyLogs: false,
    minLevel: 2,
    // prettyLogStyles: {
    //     filePathWithLine: 'hidden',
    //     dateIsoStr: 'hidden',
    // }
});

export function setRootLoggerSettings(settings: any) {
    console.log('setRootLoggerSettings', merge({}, rootNativeLogger.settings, settings));
    rootNativeLogger.settings = merge({}, rootNativeLogger.settings, settings);
}
export const rootLogger = Logger('@t', rootNativeLogger);

/**
 * @param name имя пакета, под которым будут выводиться сообщения в лог
 * @returns функции вывода с соответствующим уровнем логирования
 */
export function Logger(moduleName: string, parentLogger?: TsLogger<ILogObj>, mdc?: ILogRecord): TLogger {
    // const name = `@t:${moduleName}`;
    const name = moduleName;

    const logger = (parentLogger ?? rootLogger.logger).getSubLogger({ name }, mdc);

    // logger.info(`Logger ${name} initialized`);

    return {
        Trace: (...props) => logger.trace(...props),
        Debug: (...props) => logger.debug(...props),
        Log: (...props) => logger.info(...props),
        Warn: (...props) => logger.warn(...props),
        Err: (...props) => logger.error(...props),
        logger,
    };
}

export function subLogger(logger: TLogger, moduleName: string, mdc?: ILogRecord): TLogger {
    const name = moduleName;

    const subLogger = logger.logger.getSubLogger({ name, minLevel: 2 }, mdc);

    // subLogger.settings.minLevel = 2;

    // subLogger.info(`SubLogger ${name} initialized`);

    return {
        Trace: (...props) => subLogger.trace(...props),
        Debug: (...props) => subLogger.debug(...props),
        Log: (...props) => subLogger.info(...props),
        Warn: (...props) => subLogger.warn(...props),
        Err: (...props) => subLogger.error(...props),
        logger: subLogger,
    };
}

export default Logger;
