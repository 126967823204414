import {
    Button,
    ButtonGroup,
    capitalize,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Typography,
} from '@mui/material';
import { getEventRunJoinUrl } from '@tellsla/serverApi';
import parse from 'html-react-parser';
import { isError, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NoDataPlaceholder } from '../common/NoDataPlaceholder';

interface IEventRunCard {
    eventInfo: any;
    sx: any;
}

export default function EventRunCard({ eventInfo, sx }: IEventRunCard) {
    const { t } = useTranslation();

    const noEventData = {
        title: t('No such event'),
        description: t('Nothing found on the server'),
        status: t('no data'),
        eventType: t('no data'),
    };

    return (
        <Card
            sx={{
                minWidth: '260px',
                minHeight: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                ...sx,
            }}
        >
            {isNil(eventInfo) || isError(eventInfo) ? (
                <NoDataPlaceholder isLoading />
            ) : (
                <>
                    <CardHeader title={eventInfo.title} />
                    <CardContent
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            paddingTop: 0,
                            overflow: 'auto',
                        }}
                    >
                        {eventInfo.description ? (
                            parse(eventInfo.description ?? t('No description.'))
                        ) : (
                            <p>{t('No description.')}</p>
                        )}
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'space-between' }}>
                        <Typography variant="caption">
                            {`${capitalize(eventInfo.access?.privacy?.toLowerCase() ?? '')}`}
                            <br />
                            {`${capitalize(eventInfo.eventType?.toLowerCase() ?? '')}`}
                        </Typography>

                        <ButtonGroup size="small" variant="text">
                            <Button
                                // size="small" variant='contained' color='secondary'
                                disabled={eventInfo === noEventData}
                                onClick={() => window.location.pathname = getEventRunJoinUrl(eventInfo.eventRunId)}
                            >
                                {t('Attend')}
                            </Button>
                            {/* <Button
                                // size="small" variant='contained' color='secondary'
                                disabled={eventInfo === noEventData}
                                onClick={() => navigate(getEventRunJoinUrl(eventInfo.eventRunId))}>Admin</Button> */}
                        </ButtonGroup>
                    </CardActions>
                </>
            )}
        </Card>
    );
}

EventRunCard.propTypes = {
    eventInfo: PropTypes.object,
    sx: PropTypes.object,
};
