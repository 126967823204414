import React from "react";
import { Badge, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDiscount } from "./TariffInfoBoard";
import { theme } from "@tellsla/ui-run";

interface IDiscountGridProps {
    discounts: Array<IDiscount>;
    setDiscount: (e: IDiscount) => void;
    currentDiscount: IDiscount;
}

export const DiscountGrid: React.FC<IDiscountGridProps> = ({
    discounts = [],
    setDiscount,
    currentDiscount = { discount: 0, monthNumber: 1 }
}) => {
    const { t } = useTranslation();
    return (
        <Grid
            direction={"row"}
            xs={12}
            sx={{
                justifyContent: "center",
                margin: "12px auto",
                width: "auto",
                borderRadius: "10px",
                backgroundColor: "white",
            }}
        >
            {discounts.map((disc, key) => (
                <Badge key={key} color={"primary"} badgeContent={disc?.discount ? disc?.discount + "%" : 0} overlap="circular">
                    <Button
                        // переопределён радиус границы на 6px
                        variant={disc.monthNumber == currentDiscount?.monthNumber ? "contained" : "outlined"}
                        onClick={() => setDiscount({ discount: disc.discount, monthNumber: disc.monthNumber })}
                        sx={{
                            margin: "4px 8px",
                            padding: "4px",
                            borderRadius: "10px",
                            minWidth: "72px",
                            color: disc.monthNumber == currentDiscount?.monthNumber ? "white" : "auto",
                            backgroundColor: disc.monthNumber == currentDiscount?.monthNumber ? theme.palette.secondary.main : "white",
                        }}
                    >
                        {disc.monthNumber} {t("mon.")}
                    </Button>
                </Badge>
            ))}
        </Grid>
    );
};
