import { useTheme, Link, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LandingMainMenu(): React.JSX.Element {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack direction="row" spacing={4}>
            <Link underline="none" href="/admin">
                <Button id='login-button' variant='text' sx={{ color: theme.palette.primary.contrastText }}>{t('Login')}</Button>
            </Link>
            <Link underline="none" href="/register">
                <Button id='register-button' variant='text' sx={{ color: theme.palette.primary.contrastText }}>{t('Register')}</Button>
            </Link>
            <Link underline="none" href="/tariffs">
                <Button variant='text' sx={{ color: theme.palette.primary.contrastText }}>{t('Tariffs')}</Button>
            </Link>
        </Stack>
    );
}
