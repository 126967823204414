import React from 'react';

export interface IEventRunContext {
    eventRunId: string | null;
    eventId: string | null;
    isConnected: boolean;
    isAuthenticated: boolean;
    isOrg: boolean;
    userId: string | null;
    users: {
        [key: string]: any;
    };
    serverTimeDifference: number;
}

export const EventRunContextDefaults: IEventRunContext = {
    eventRunId: null,
    eventId: null,
    isConnected: false,
    isAuthenticated: false,
    isOrg: false,
    userId: null,
    users: {},
    serverTimeDifference: 0,
}

export const EventRunContext = React.createContext<IEventRunContext>(EventRunContextDefaults);
