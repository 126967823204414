import { WSCommands } from "@tellsla/serverTypes";
import { Logger } from "../..";
import { eventRunRoles, canChangeLayout, isModeratorRole, isOrgRole, peerRoleCan, peerRoleIs } from "./eventRunRoles";

type TAuthConfiguration = {
    mustHaveAuth: boolean;
    can: (role: string) => boolean;
}

const commandsConfig = new Map<WSCommands, TAuthConfiguration>([
    [WSCommands.Authorize, { mustHaveAuth: false, can: () => true }],
    [WSCommands.JoinEvent, { mustHaveAuth: true, can: () => true }],
    // [WSCommands.ExitRoom, { mustHaveAuth: true, can: () => true }], // не используется
    // [WSCommands.Disconnect, { mustHaveAuth: false, can: () => true }], // авто-событие, его не отправляют
    [WSCommands.Logout, { mustHaveAuth: true, can: () => true }],

    [WSCommands.RaiseHand, { mustHaveAuth: true, can: () => true }],
    [WSCommands.ExpressEmoji, { mustHaveAuth: true, can: () => true }],

    [WSCommands.MicIsOn, { mustHaveAuth: true, can: () => true }],

    [WSCommands.UpdateToken, { mustHaveAuth: false, can: () => true }],
    // [WSCommands.ChangeUser, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isModer') }], // не используется

    [WSCommands.EventRun, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    [WSCommands.TimerCommand, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    [WSCommands.TimeLineAppend, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],

    [WSCommands.Layout, { mustHaveAuth: true, can: canChangeLayout }],
    [WSCommands.ExpositionUpdate, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    [WSCommands.RoomUpdate, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    [WSCommands.TableUpdate, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isModer') }],

    [WSCommands.RelocateUsers, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],

    [WSCommands.TableIsolate, { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],
    [WSCommands.TableUnite, { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],
    [WSCommands.TablesIsolateAll, { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],
    [WSCommands.TablesUniteAll, { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'eventRun', 'tableIsolation') }],

    [WSCommands.PlaybookCommand, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    [WSCommands.PlaybookGet, { mustHaveAuth: true, can: () => true }],

    // [WSCommands.TimerCommand, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }], // не используется

    [WSCommands.MediaCommand, { mustHaveAuth: true, can: (role: string): boolean => peerRoleIs(role, 'isOrg') }],
    [WSCommands.PlaybookTelemetry, { mustHaveAuth: true, can: () => true }],


    // Quiz answers
    [WSCommands.QuizRunCommand, { mustHaveAuth: true, can: (role: string): boolean => isOrgRole(role) }],
    [WSCommands.QuizRunGet, { mustHaveAuth: true, can: (): boolean => true }],

    // Quiz telemetry
    [WSCommands.QuizRunActivity, { mustHaveAuth: true, can: () => true }],

    [WSCommands.TableListening, { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'table', 'listen') }],

    [WSCommands.MSGetProducers, { mustHaveAuth: true, can: () => true }],

    // ['getRouterRtpCapabilities', { mustHaveAuth: true, can: () => true }], // не используется
    [WSCommands.MSCreateWebRtcTransport, { mustHaveAuth: true, can: () => true }],
    [WSCommands.MSTransportConnect, { mustHaveAuth: true, can: () => true }],
    [WSCommands.MSTransportProduce, { mustHaveAuth: true, can: () => true }],

    // ['consume', { mustHaveAuth: true, can: () => true }], // не используется
    [WSCommands.MSConsumerResume, { mustHaveAuth: true, can: () => true }],

    [WSCommands.MSProducerClosed, { mustHaveAuth: true, can: () => true }],

    // ['userlist', { mustHaveAuth: true, can: () => false }], // не используется
    // [WSCommands.UserListGet, { mustHaveAuth: true, can: () => true }], // не используется

    [WSCommands.UserListCommand, { mustHaveAuth: true, can: isModeratorRole }],

    // Chat
    [WSCommands.ChatMessage, { mustHaveAuth: true, can: () => true }],

    // User Telemetry
    [WSCommands.UserChangeAppFocus, { mustHaveAuth: true, can: (role: string): boolean => peerRoleCan(role, 'userTelemetry', 'change') }],
    [WSCommands.MediaStatsGet, { mustHaveAuth: true, can: () => true }],

    // ['jobCreateUpload', { mustHaveAuth: true, can: () => true }], // не используется
    // ['jobCancelUpload', { mustHaveAuth: true, can: () => true }], // не используется
]);

const logger = Logger('isCommandAllowed');

export const isCommandAllowed = (command: WSCommands, role: string, haveAuth: boolean): { result: boolean; message: string; } => {
    const commandConfig = commandsConfig.get(command);

    if (!commandConfig) {
        logger.Debug(`Unknown command [${command}]`);
        return { result: false, message: `Unknown command [${command}]` };
    }

    if (commandConfig.mustHaveAuth && !(haveAuth === true)) {
        logger.Debug(`Must be authorized for [${command}]`);
        return { result: false, message: `Must be authorized for [${command}]` };
    }

    if (!commandConfig.can(role)) {
        logger.Debug(`${role} don't have enough priviliges for [${command}]`);
        logger.Debug({ roleCfg: eventRunRoles.get(role.toUpperCase())})
        return { result: false, message: `No enough priviliges for [${command}]` };
    }

    return { result: true, message: '' };
};

// export default {
//     isCommandAllowed,
// };
