import React from 'react';

interface KVType {
    [key: string]: unknown;
}

interface UploadPropsContext {
    uploadProps: KVType,
}

export const UploadPropsContext = React.createContext<UploadPropsContext>({
    uploadProps: {}
});