/// <reference types="vite/client" />
import { isNil } from 'lodash-es';
import { commonConfig } from './commonConfig';

const CAM_VIDEO_SIMULCAST_SM_ENCODINGS = [
    { rid: 'r0', scalabilityMode: 'S1T3', scaleResolutionDownBy: 3, maxBitrate: 350000 },
    { rid: 'r1', scalabilityMode: 'S1T3', scaleResolutionDownBy: 1.5, maxBitrate: 400000 },
    { rid: 'r2', scalabilityMode: 'S1T3', scaleResolutionDownBy: 1, maxBitrate: 500000 },
];

const dtxVal = false;
const CAM_VIDEO_SIMULCAST_DOC3_ENCODINGS_VGA = [
    { rid: 'r0', dtx: dtxVal, scaleResolutionDownBy: 1.3, maxBitrate: 350000, maxFrameRate: 15 },
    { rid: 'r1', dtx: dtxVal, scaleResolutionDownBy: 1.15, maxBitrate: 400000, maxFrameRate: 15 },
    { rid: 'r2', dtx: dtxVal, scaleResolutionDownBy: 1, maxBitrate: 500000, maxFrameRate: 15 },
];
const CAM_VIDEO_SIMULCAST_DOC3_ENCODINGS_HD = [
    { rid: 'r0', dtx: dtxVal, scaleResolutionDownBy: 1.5, maxBitrate: 500000, maxFrameRate: 15 },
    { rid: 'r1', dtx: dtxVal, scaleResolutionDownBy: 1.25, maxBitrate: 700000, maxFrameRate: 15 },
    { rid: 'r2', dtx: dtxVal, scaleResolutionDownBy: 1, maxBitrate: 1000000, maxFrameRate: 15 },
];
const CAM_VIDEO_SIMULCAST_DOC3_ENCODINGS_FHD = [
    { rid: 'r0', dtx: dtxVal, scaleResolutionDownBy: 2, maxBitrate: 1000000, maxFrameRate: 15 },
    { rid: 'r1', dtx: dtxVal, scaleResolutionDownBy: 1.5, maxBitrate: 2000000, maxFrameRate: 15 },
    { rid: 'r2', dtx: dtxVal, scaleResolutionDownBy: 1, maxBitrate: 3700000, maxFrameRate: 15 },
];

// Used for VP9 webcam video.
const WEBCAM_KSVC_ENCODINGS = [{ scalabilityMode: 'S3T3_KEY' }];

// Used for simulcast screen sharing.
const SCREEN_SHARING_SIMULCAST_ENCODINGS = [
    { dtx: true, scaleResolutionDownBy: 2, maxBitrate: 3500000 },
    { dtx: true, scaleResolutionDownBy: 1, maxBitrate: 6000000 },
];

// Used for VP9 screen sharing.
const SCREEN_SHARING_SVC_ENCODINGS = [{ scalabilityMode: 'S3T3', dtx: true }];

let webDomain = import.meta.env?.['WEB_DOMAIN'];
if (isNil(webDomain)) {
    // eslint-disable-next-line prefer-destructuring
    if (typeof window !== 'undefined') webDomain = window?.location.href.split('/')[2];
    else webDomain = 'sfu.viltime.ru';
}

// const cutWww = /\b(?!www\.)(?:[0-9A-Za-z][0-9A-Za-z-]{0,62})(?:\.(?:[0-9A-Za-z][0-9A-Za-z-]{0,62}))*(?:\.?|\b)/g;

const BASE_MEDIA_ASPECT_RATIO = 1.777777778; // 16x9

export const config = {
    ...commonConfig,

    frontendHost: {
        protocol: 'https',
        host: webDomain,
        port: 443,
        timeout: 12000,
        fallbackPath: '/',
        adminPath: '/admin',
        // adminPath: '/admin/#',
        fullUrl: '',
    },

    backendHost: {
        protocol: 'https',
        host: webDomain,
        port: 443,
        timeout: 12000,
        fullUrl: '',

        restApi: {
            path: '/api',
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
            },
        },

        wsApi: {
            path: '/server',
        },

        pollingTime: {
            mediafile: 2000,
            playbook: 3000,
        },
    },

    interMessageTimeout: 15000,

    logoutPath: '/',

    logs: {
        minLevel: 2,
    },

    defaults: {
        mediaAspectRatio: BASE_MEDIA_ASPECT_RATIO,
        userVideoAspectRatio: 1.1,
        lineGridAspectRatio: 1,
        tableUserCardAspectRatio: 1, // 0.55,
        tableUserCardAspectRatioRange: {
            port: 1,
            land: 6,
        },
        speakers: {
            AspectRatioRange: {
                port: 0.75, // 3x4
                land: 1.333333, // 4x3
                // port: 0.5625, // 9x16
                // land: 1.77777778, // 16x9
            },
            minSize: {
                height: 150, // Px sized
                width: 150, // Px sized
            },
        },
        attenders: {
            AspectRatioRange: {
                port: 0.5625,
                land: 1.77777778,
            },
            minSize: {
                height: 150, // Px sized
                width: 150, // Px sized
            },
        },
        presenter: {
            AspectRatioRange: {
                port: 1.77777778,
                land: 1.77777778,
            },
            minSize: {
                height: 150, // Px sized
                width: 150, // Px sized
            },
        },
        mediaGridElement: {
            minHeight: 120, // Px sized
            maxHeight: 120, // Px sized
            minWidth: Math.round(120 * BASE_MEDIA_ASPECT_RATIO), // Px sized
            maxWidth: Math.round(120 * BASE_MEDIA_ASPECT_RATIO), // Px sized
        },
        getRegistrationCodeTimeout: 30000,
    },
    expo: {
        maxFlyingCardsNo: 5,
        expressEmojiTimeout: 10000,
    },
    preload: {
        aheadNum: 10,
        backwardNum: 20,
    },

    bitFrequency: {
        videoTimePublish: 5000, // on video display every 5 sec to send currentTime update
    },

    hotKeys: {
        playListCollapse: '`',
        playListCurrentPageIncrease: ['right', 'down', 'space'],
        playListCurrentPageDecrease: ['left', 'up', 'backspace'],
        playListCurrentPageToEnd: ['end'],
        playListCurrentPageToStart: ['home'],
        rightDrawerCollapse: 'Alt+`',
        layouts: [
            { id: 'left', key: 'Alt+Shift+a' },
            { id: 'right', key: 'Alt+Shift+d' },
            { id: 'up', key: 'Alt+Shift+w' },
            { id: 'down', key: 'Alt+Shift+s' },
            { id: 'rotate', key: 'Alt+Shift+r' },
            { id: 'minimize', key: 'Alt+Shift+z' },
            { id: 'set-1', key: 'Alt+1' },
            { id: 'set-2', key: 'Alt+2' },
            { id: 'set-3', key: 'Alt+3' },
            { id: 'set-4', key: 'Alt+4' },
            { id: 'set-5', key: 'Alt+5' },
            { id: 'set-6', key: 'Alt+6' },
        ],
        // layouts: [
        //     { id: '1', key: 'Alt+1' },
        //     { id: '2', key: 'Alt+2' },
        //     { id: '3', key: 'Alt+3' },
        //     { id: '4', key: 'Alt+4' },
        // ],
    },

    videoConstraints: {
        width: {
            // min: 640,
            ideal: 1920,
            // max: 1920,
        },
        // height: {
        //     min: 720,
        //     ideal: 720,
        //         max: 1080,
        // },
        frameRate: { ideal: 15 },
        // maxFrameRate: '15',
        // aspectRatio: {
        //     // ideal: 1.7777777778
        //     ideal: 1.5
        // }
    },

    eventTokenFileName: 'eventToken',
    adminTokenFileName: 'userToken',

    initialMediaConfig: {
        video: false,
        audio: false,
    },

    CAM_VIDEO_SIMULCAST_SM_ENCODINGS,
    CAM_VIDEO_ENCODINGS_VGA: CAM_VIDEO_SIMULCAST_DOC3_ENCODINGS_VGA,
    CAM_VIDEO_ENCODINGS_HD: CAM_VIDEO_SIMULCAST_DOC3_ENCODINGS_HD,
    CAM_VIDEO_ENCODINGS_FHD: CAM_VIDEO_SIMULCAST_DOC3_ENCODINGS_FHD,
    WEBCAM_KSVC_ENCODINGS,
    SCREEN_SHARING_SVC_ENCODINGS,
    SCREEN_SHARING_SIMULCAST_ENCODINGS,
};

// TODO: избавиться от этого отдельного объявления переменной
export const frontendUrl = `${config.frontendHost.protocol}://${config.frontendHost.host}:${config.frontendHost.port}`;
config.frontendHost.fullUrl = frontendUrl;

export const backendUrl = `${config.backendHost.protocol}://${config.backendHost.host}:${config.backendHost.port}`;
config.backendHost.fullUrl = backendUrl;
