/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component, WheelEvent } from 'react';
import PropTypes from 'prop-types';
import './style.css';
import { WheelPickerDefaultColumnDivider } from './WheelPickerDefaultColumnDivider';


class PickerColumn extends Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        itemHeight: PropTypes.number.isRequired,
        columnHeight: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0,
            ...this.computeTranslate(props),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isMoving) {
            return;
        }
        this.setState(this.computeTranslate(nextProps));
    }

    computeTranslate = (props) => {
        const { options, value, itemHeight, columnHeight } = props;
        let selectedIndex = options.indexOf(value);
        if (selectedIndex < 0) {
            // throw new ReferenceError();
            console.warn('Warning: "' + this.props.name + '" doesn\'t contain an option of "' + value + '".');
            this.onValueSelected(options[0]);
            selectedIndex = 0;
        }
        return {
            scrollerTranslate: columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
            minTranslate: columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
            maxTranslate: columnHeight / 2 - itemHeight / 2,
        };
    };

    onValueSelected = (newValue) => {
        this.props.onChange(this.props.name, newValue);
    };

    handleTouchStart = (event) => {
        const startTouchY = event.targetTouches[0].pageY;
        this.setState(({ scrollerTranslate }) => ({
            startTouchY,
            startScrollerTranslate: scrollerTranslate,
        }));
    };

    handleMouseDown = (event) => {
        if (event.buttons === 1) {
            const startTouchY = event.pageY;
            this.setState(({ scrollerTranslate }) => ({
                startTouchY,
                startScrollerTranslate: scrollerTranslate,
            }));
        }
    };

    handleMove = (touchY) => {
        this.setState(({ isMoving, startTouchY, startScrollerTranslate, minTranslate, maxTranslate }) => {
            if (!isMoving) {
                return {
                    isMoving: true,
                };
            }

            let nextScrollerTranslate = startScrollerTranslate + touchY - startTouchY;
            if (nextScrollerTranslate < minTranslate) {
                nextScrollerTranslate = minTranslate - Math.pow(minTranslate - nextScrollerTranslate, 0.8);
            } else if (nextScrollerTranslate > maxTranslate) {
                nextScrollerTranslate = maxTranslate + Math.pow(nextScrollerTranslate - maxTranslate, 0.8);
            }
            return {
                scrollerTranslate: nextScrollerTranslate,
            };
        });
    };

    handleTouchMove = (event) => {
        event.preventDefault();
        const touchY = event.targetTouches[0].pageY;
        this.handleMove(touchY);
    };

    handleMouseMove = (event) => {
        if (event.buttons === 1) {
            event.preventDefault();
            const touchY = event.pageY;
            this.handleMove(touchY);
        }
    };

    handleTouchEnd = (event) => {
        if (!this.state.isMoving) {
            return;
        }
        this.setState({
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0,
        });
        setTimeout(() => {
            this.postMove();
        }, 0);
    };

    handleTouchCancel = (event) => {
        if (!this.state.isMoving) {
            return;
        }
        this.setState((startScrollerTranslate) => ({
            isMoving: false,
            startTouchY: 0,
            startScrollerTranslate: 0,
            scrollerTranslate: startScrollerTranslate,
        }));
    };

    handleItemClick = (option) => {
        if (option !== this.props.value) {
            this.onValueSelected(option);
        }
    };

    postMove() {
        const { options, itemHeight } = this.props;
        const { scrollerTranslate, minTranslate, maxTranslate } = this.state;
        let activeIndex;
        if (scrollerTranslate > maxTranslate) {
            activeIndex = 0;
        } else if (scrollerTranslate < minTranslate) {
            activeIndex = options.length - 1;
        } else {
            activeIndex = Math.floor(-(scrollerTranslate - maxTranslate) / itemHeight);
        }
        this.onValueSelected(options[activeIndex]);
    }

    postWheel() {
        const that = this;
        setTimeout(() => {
            if (that.state.isScrolling > Date.now() - 250) {
                this.postWheel();
                return;
            }
            this.postMove();
        }, 250);
    }

    handleScroll = (event) => {
        // Support for keyboard up/down
        let deltaY;
        if (!!event.keyCode && (event.keyCode == 38 || event.keyCode == 40)) {
            deltaY = event.keyCode == 38 ? 53 : -53;
        } else if (!!event.deltaY) {
            deltaY = event.deltaY;
        } else {
            deltaY = 0;
        }

        this.setState(({ scrollerTranslate, minTranslate, maxTranslate }) => {
            const newValue = (scrollerTranslate || 0) + Math.round(deltaY);
            const newTranslate = Math.max(
                minTranslate,
                Math.min(maxTranslate, (scrollerTranslate || 0) + Math.round(deltaY))
            );

            this.postWheel();

            return {
                scrollerTranslate: newTranslate,
                isScrolling: Date.now(),
            };
        });
    };

    renderItems() {
        const { options, itemHeight, value } = this.props;
        return options.map((option, index) => {
            const style = {
                height: itemHeight + 'px',
                lineHeight: itemHeight + 'px',
            };
            const className = `picker-item${option === value ? ' picker-item-selected' : ''}`;
            return (
                <div
                    key={index}
                    className={className}
                    style={style}
                    onClick={() => this.handleItemClick(option)}
                >
                    {option}
                </div>
            );
        });
    }

    render() {
        const { tabIndex } = this.props;
        const translateString = `translate3d(0, ${this.state.scrollerTranslate}px, 0)`;
        const style = {
            MsTransform: translateString,
            MozTransform: translateString,
            OTransform: translateString,
            WebkitTransform: translateString,
            transform: translateString,
        };
        if (this.state.isMoving) {
            style.transitionDuration = '0ms';
        }
        return (
            <div className="picker-column">
                <div
                    tabIndex={tabIndex}
                    className="picker-scroller"
                    style={style}
                    onTouchStart={this.handleTouchStart}
                    onTouchMove={this.handleTouchMove}
                    onTouchEnd={this.handleTouchEnd}
                    onTouchCancel={this.handleTouchCancel}
                    onMouseDown={this.handleMouseDown}
                    onMouseMove={this.handleMouseMove}
                    onMouseUp={this.handleTouchEnd}
                    onMouseLeave={this.handleTouchEnd}
                    onWheel={this.handleScroll}
                    onKeyDown={this.handleScroll}
                >
                    {this.renderItems()}
                </div>
            </div>
        );
    }
}

export class WheelPickerJS extends Component {
    static propTypes = {
        optionGroups: PropTypes.object.isRequired,
        valueGroups: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        itemHeight: PropTypes.number,
        height: PropTypes.number,
        columnsDivider: PropTypes.node,
    };

    static defaultProps = {
        itemHeight: 36,
        height: 216,
        columnsDivider: <WheelPickerDefaultColumnDivider />
    };

    renderInner() {
        const { optionGroups, valueGroups, itemHeight, height, onChange, columnsDivider } = this.props;
        const highlightStyle = {
            height: itemHeight,
            marginTop: -(itemHeight / 2),
        };
        let index = 1000;
        const columnNodes = [];
        for (let name in optionGroups) {
            columnNodes.push(
                <PickerColumn
                    tabIndex={index++}
                    key={name}
                    name={name}
                    options={optionGroups[name]}
                    value={valueGroups[name]}
                    itemHeight={itemHeight}
                    columnHeight={height}
                    onChange={onChange}
                />
            );
            if (columnsDivider) {
                columnNodes.push(<React.Fragment key={`${name}-divider`}>{columnsDivider}</React.Fragment>);
            }
        }

        if (columnsDivider && columnNodes.length > 0) {
            columnNodes.pop();
        }

        return (
            <div className="picker-inner">
                {columnNodes}
                <div className="picker-highlight" style={highlightStyle}></div>
            </div>
        );
    }

    render() {
        const style = {
            height: this.props.height,
        };

        return (
            <div className="picker-container" style={style}>
                {this.renderInner()}
            </div>
        );
    }
}
