import { isEmpty, isError, isNil } from 'lodash-es';
import { restAPI } from './rest/restApi';

import CSJ, { Logger } from '@tellsla/common';

const logger = Logger('csjApi');

export function csjApiPost(resource: string, itemId: string | null, suffix: string, csjData: any = {}) {
    return restAPI()
        .post(
            `/${resource}` + (!isNil(itemId) ? `/${itemId}` : '') + (!isNil(suffix) ? `/${suffix}` : ''),
            csjData,
        )
        .then((res) => {
            return CSJ.getData(res.data);
        });
}

/**
 * Отправляет на сервер POST запрос, сформированный как /resource/id/suffix, данные передаются в body в формате CSJ
 * @param resource имя ресурса
 * @param itemId идентификатор на ресурсе
 * @param suffix опциональный дополнительный указатель действий для запроса
 * @param itemData данные, сопровождающие запрос
 * @returns если всё в порядке генерирует resolve( response_data ), возвращая полученный от сервера ответ,
 * в случае ошибки генерирует reject( error ) со значением ошибки, случившейся или переданной с сервера
 */
export function postCSJItem(resource: string, itemId: string | null, suffix?: string, itemData?: any) {
    return new Promise<any>((resolve, reject) => {
        if (isEmpty(resource)) {
            reject(new Error(`Empty resource param [${resource}]`));
        }

        restAPI()
            .post(
                `/${resource}` + (!isNil(itemId) ? `/${itemId}` : '') + (!isNil(suffix) ? `/${suffix}` : ''),
                itemData ?? {},
            )
            .then((res) => {
                const result = CSJ.getItems(res.data) ?? [];
                // if (isError(result)) reject(result);
                if (isError(result)) resolve(result);
                else resolve(result?.length > 1 ? result : result[0]);
            })
            .catch((err) => {
                if (err.response?.status >= 500) {
                    reject(new Error('Server not available'));
                    return;
                }
                reject(err);
            });
    });
}

/**
 * Отправляет на сервер GET запрос, сформированный как /resource/id/suffix
 * @param resource имя ресурса
 * @param itemId идентификатор на ресурсе
 * @param suffix опциональный дополнительный указатель действий для запроса
 * @returns если всё в порядке генерирует resolve( response_data ), возвращая полученный от сервера ответ,
 * в случае ошибки генерирует reject( error ) со значением ошибки, случившейся или переданной с сервера
 */
export function getCSJItem(resource: string, itemId: string, suffix?: string): Promise<any> {
    return new Promise((resolve, reject) => {
        if (isNil(resource) || isEmpty(resource)) {
            reject(new Error(`Invalid resource ${resource}`));
        }

        restAPI()
            .get(`/${resource}` + (!isNil(itemId) ? `/${itemId}` : '') + (!isNil(suffix) ? `/${suffix}` : ''))
            .then((res) => {
                const result = CSJ.getItem(res.data);
                if (isError(result)) reject(result);
                else resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
}


/**
 * Функция для использования в react-admin среде для обращения к серверу, заворачивающему данные в CSJ
 * @param resource имя ресурса данных
 * @param method запрашиваемый метод обработки данных
 * @param params параметры вызова метода: сортировки, постраничного обмена и тд
 * @param messageData данные для метода
 * @returns чистые данные полученного от сервера ответа или Error
 */
export function csjApiMethod(resource: string, method: string, params: unknown, messageData?: never) {
    logger.Debug('CSJ %s %s', resource, method, {
        params,
        messageData,
    });

    if (isEmpty(resource) || isEmpty(method)) {
        logger.Debug('csjApiCommand Invalid params: ', { resource, csjMethod: method });
        return Promise.reject(new Error('Invalid params'));
    }

    return csjApiPost(resource, null, 'csj', CSJ.buildRequest(method, params, messageData));
}

/**
 * CSJ функция получения одной записи от указанного ресурса
 * @param resource имя реурса данных
 * @param itemId идентификатор записи
 * @returns чистые данные полученного от сервера ответа или Error
 */
export function csjGetOne(resource: string, itemId: string) {
    logger.Debug('getOne %s/%s data with CSJ', resource, itemId);

    if (isEmpty(resource) || isEmpty(itemId)) {
        logger.Debug('csjGetOne Invalid params: ', { resource, itemId });
        return new Error('Invalid params');
    }

    return csjApiPost(resource, null, 'csj', CSJ.buildRequest('getOne', { id: itemId }, {}));
}

/**
 * CSJ функция создания новой записи для указанного ресурса
 * @param resource имя реурса данных
 * @param itemId идентификатор записи
 * @returns чистые данные полученного от сервера ответа или Error
 */
export function csjCreate(resource: string, data: never) {
    logger.Debug('create %s/%s data with CSJ', resource, data);

    return csjApiPost(resource, null, 'csj', CSJ.buildRequest('CREATE', {}, data));
}

// TODO проверить на дубликат с объявлениями в серверной части
export enum IEventTokenRequestTypes {
    EVENT = 'EVENT',
    ATTENDER = 'ATTENDER',
}

interface IEventTokenRequest {
    eventRunId: string;
    token: string;
    tokenType: IEventTokenRequestTypes;
}

export const getEventRunToken = (eventData: IEventTokenRequest) =>
    postCSJItem('eventrun', eventData.eventRunId, 'token', eventData);

export const getEventRunAccessToken = (userData: IEventTokenRequest) =>
    getEventRunToken({ ...userData, tokenType: IEventTokenRequestTypes.EVENT });

export const getEventRunAttenderToken = (userData: IEventTokenRequest) =>
    getEventRunToken({
        ...userData,
        tokenType: IEventTokenRequestTypes.ATTENDER,
    });

export const updateEventRunToken = (token: string, fields: unknown) =>
    postCSJItem('eventrun', null, 'updateToken', { token, fields });

export const getEventRunPublicData = (eventRunId: string) => getCSJItem('eventrun', eventRunId, 'publicData');

export const getEventPublicData = (eventId: string) => getCSJItem('event', eventId, 'publicData');

// Register User
export const registerUser = (userData: unknown) => postCSJItem('users', null, 'register', userData);

// Request verification code
export const requestVerificationCode = (userData: unknown) => postCSJItem('users', null, 'getcode', userData);
// Отправить запрос на восстановление пароля
export const requestPasswordRestore = (userData: unknown) => postCSJItem('users', null, 'restorePassword', userData);

// Switch workspace
export const sendSwitchCurrentUserWorkspace = (targetWorkspaceId: string) =>
    postCSJItem(
        'users',
        null,
        'csj',
        CSJ.buildRequest('CHANGEWS', {
            targetWorkspaceId,
        }),
    );

interface IUserLoginProps {
    email: string;
    password: string;
}
interface IUserLoginResult {
    token: string;
}
/**
 * Отправляем на сервер креденшиалсы пользователя на проверку
 * @param userData структура { email, password }
 * @returns структура { token } или Error
 */
export const loginUser = (userData: IUserLoginProps): Promise<IUserLoginResult | Error> =>
    postCSJItem('users', null, 'login', userData);

interface ICheckUserTokenParams {
    token: string;
}
interface ICheckUserTokenResult {
    success: boolean;
    token: string;
}
/**
 * Проверяет на сервере токен пользователя на валидность.
 * @param userData Структура { token }
 * @returns результат проверки токена в виде { success: boolean, token: string } или Error. Значение токена возвращается из запроса.
 */
export const checkUserToken = (userData: ICheckUserTokenParams): Promise<ICheckUserTokenResult | Error> =>
    postCSJItem('users', null, 'checkToken', userData);
