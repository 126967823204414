import React from 'react';
import { LandingFrame } from './LandingFrame';
import { LandingHeader } from './LandingHeader';
import LandingMainMenu from './LandingMainMenu';

export function LandingBase(props: React.PropsWithChildren<any>): React.JSX.Element {
    return (
        <LandingFrame>
            <LandingHeader mainMenu={<LandingMainMenu />} />

            {props.children}
        </LandingFrame>
    );
}
