import React from 'react';

export interface IVideoPlayerControllerContext {
    speakerDeviceId: string | null;
    cachedVideoStatus: any;
    cachedVideoTimeMark: number | null;
    cachedVideoIsMuted: boolean;
}
export const VideoPlayerControllerContextDefaults: IVideoPlayerControllerContext = {
    speakerDeviceId: null,
    cachedVideoStatus: null,
    cachedVideoTimeMark: null,
    cachedVideoIsMuted: false,
};

export const VideoPlayerControllerContext = React.createContext<IVideoPlayerControllerContext>(
    VideoPlayerControllerContextDefaults
);
