import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: true,

        fallbackLng: 'en',

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        backend: {
            loadPath: '/locales/{{lng}}/translation.json',
        },
        detection: {
            debug: true,
            order: ['navigator', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
        },
    });

export default i18n;
