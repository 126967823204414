import React from 'react';
import { LandingBase } from '@tellsla/ui-run';
import HomePage from './pages/HomePage';

function LandingHomePage() {
    return (
        <LandingBase>
            <HomePage />
            {/*
            <Stack direction="row" spacing={2} maxHeight="80vh" height="20em">
                <EventRunInfo eventId="623cc1249861ff8d507d5ddf" sx={EventRunCardExtraStyle} />
                <EventRunInfo eventId="6048d994da5fb07ad993cd8d" sx={EventRunCardExtraStyle} />
                <EventRunInfo eventId="61563e35ea631c57c213f095" sx={EventRunCardExtraStyle} />
            </Stack> */}
        </LandingBase>
    );
}

export default LandingHomePage;
