import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    styled,
    Typography,
    useTheme
} from '@mui/material';
import { LandingInfoBlock } from '@tellsla/ui-run';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IInfoBlockListItemProps {
    icon?: JSX.Element;
}

function InfoBlockListItem(props: React.PropsWithChildren<IInfoBlockListItemProps>) {
    return (
        <ListItem>
            <ListItemIcon>{props.icon || <CheckIcon />}</ListItemIcon>
            {props.children}
        </ListItem>
    );
}

const InfoBlockList = styled(List)({
    marginLeft: 16,
    margin: 16,
});

function HomePage() {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Stack direction="column" spacing={4} sx={{ mt: theme.spacing(4) }}>
            <LandingInfoBlock title={t('platform_comfort_title')}>
                {t('platform_comfort_text')}
            </LandingInfoBlock>

            <LandingInfoBlock title={t('platform_meaning_title')}>
                {t('platform_meaning_text')}
            </LandingInfoBlock>

            <LandingInfoBlock title={t('platform_tools_title')}>
                {t('platform_tools_text_1')}

                <InfoBlockList>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_1')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_2')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_3')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_4')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_5')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_6')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_7')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_tools_list_8')} />
                    </InfoBlockListItem>
                </InfoBlockList>
                <Typography>{t('platform_tools_text_2')}</Typography>
            </LandingInfoBlock>

            <LandingInfoBlock title={t('platform_materials_title')}>
                {t('platform_materials_text')}
            </LandingInfoBlock>

            <LandingInfoBlock title={t('platform_is_and_not_title')}>
                <Typography variant="h6">{t('platform_not_text')}</Typography>
                <InfoBlockList>
                    <InfoBlockListItem icon={<ClearIcon />}>
                        <ListItemText primary={t('platform_not_list_1')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem icon={<ClearIcon />}>
                        <ListItemText primary={t('platform_not_list_2')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem icon={<ClearIcon />}>
                        <ListItemText primary={t('platform_not_list_3')} />
                    </InfoBlockListItem>
                </InfoBlockList>
                <Typography variant="h6">{t('platform_is_text_1')}</Typography>
                <Typography>{t('platform_is_text_2')}</Typography>
                <Typography>{t('platform_is_text_3')}</Typography>
                <InfoBlockList>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_is_list_1')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_is_list_2')} />
                    </InfoBlockListItem>
                </InfoBlockList>
            </LandingInfoBlock>

            <LandingInfoBlock title={t('platform_interfaces_title')}>
                <Typography>{t('platform_interfaces_text')}</Typography>
                <InfoBlockList>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_interfaces_list_1')} />
                    </InfoBlockListItem>
                    <InfoBlockListItem>
                        <ListItemText primary={t('platform_interfaces_list_2')} />
                    </InfoBlockListItem>
                </InfoBlockList>
            </LandingInfoBlock>
        </Stack>
    );
}

export default HomePage;
