import { merge } from 'lodash-es';


export const TABLE_SET_TABLE = 'TABLE_SET_TABLE';
export const TABLE_SET_OPTIONS = 'TABLE_SET_OPTIONS';
export const TABLE_ADD_USER = 'TABLE_ADD_USER';
export const TABLE_DEL_USER = 'TABLE_DEL_USER';
export const TABLE_UPDATE_USER = 'TABLE_UPDATE_USER';
export const TABLE_REORDER_USERS = 'TABLE_REORDER_USERS';
export const TABLE_SWITCH_OPTIONS_FLAG = 'TABLE_SWITCH_OPTIONS_FLAG';

export const TABLE_OPTIONS = {
    isMicOffFlag: 'isMicOff',
    isMutedFlag: 'isMuted',
    isolationLevel: 'isolationLevel',
    canDeleteFlag: 'canDelete',
    canReorderFlag: 'canReorder',
    title: 'title',
    color: 'color'
}

export const TableIsolationLevels = {
    NONE: 'NONE',
    SOUND: 'SOUND',
    FULL: 'FULL'
}

export function defaultTableData(customData) {
    return merge({
        // id: '1',
        userList: [],   // Ordered list of user Id
        users: {},      // Map of User Id to Options
        overhear: {},   // User Ids who is listening to table directly
        options: {
            [TABLE_OPTIONS.color]: '#b2b2b2',
            [TABLE_OPTIONS.isolationLevel]: TableIsolationLevels.NONE,
            [TABLE_OPTIONS.isMutedFlag]: false,
            [TABLE_OPTIONS.canDeleteFlag]: true,
        }
    }, customData);
}
