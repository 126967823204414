import {
    Card, CardContent, CardHeader
} from '@mui/material';
import React from 'react';

interface ILandingInfoBlockProps {
    title: string,
    subheader?: string | React.JSX.Element,
}

export function LandingInfoBlock(props: React.PropsWithChildren<ILandingInfoBlockProps>) {

    const { title, subheader } = props;

    return (
        <Card sx={{ borderRadius: '10px', backgroundColor: 'background.landingCard', padding: '24px' }}>
            <CardHeader
                title={title}
                subheader={subheader}
            />
            <CardContent>
                {props.children}
                {/* <Typography variant="body2" color="text.secondary">
                </Typography> */}
            </CardContent>
        </Card>
    );
}

export default LandingInfoBlock;
