import {
    hostTableNumber,
    IQChoiceVariant,
    IQuiz,
    IQuizQuestion,
    IQuizQuestionTypes,
    IQuizRun,
    IQuizRunUserActivity,
    IQuizUserAnswer,
    IUserLocation,
} from '@tellsla/serverTypes';
import { isEmpty, isNil, isNumber, mergeWith } from 'lodash-es';
import { Logger } from '../tsLogger';
import { QuizRunUserStatuses } from './constants';

const logger = Logger('quizUtils');

/**
 * Вычисляем продолжительность между началом и окончанием
 *
 * @param {number} startTime - Время начала
 * @param {number} endTime - Время окончания
 * @return {number} Продолжительность между началом и окончанием
 */
function calcDuration(startTime: number, endTime: number) {
    return isNil(startTime) || isNil(endTime) || endTime == 0 ? 0 : endTime - startTime;
}

/**
 * Информация о столе, участвовавшем в опросе
 */
export interface IQResultsTableInfo {
    title: string; // название стола, если было
    usersNo: number; // количество пользователей за данным столом
}

/**
 * Полный набор столов, участвовавших в опросе
 * { "номер стола": IQResultsTableInfo }
 */
export interface IQResultsTables {
    [tableId: string]: IQResultsTableInfo;
}

/**
 * Общие результаты проведения опроса
 */
export interface IQResultsQuizTotals {
    startTime: number; // время начала, милисекунды
    finishTime: number; // время окончания, милисекунды
    duration?: number; // продолжительность, мишисекунды
    usersStarted: number; // количество пользователей начавших отвечать
    usersFinished: number; // количество пользователей успешно закончивших отвечать

    usersMidTime: number; // среднее время, затраченное пользователем на опрос
    // TODO: разобраться с метрикой usersMaxMidTime, там, похоже, фигня
    usersMaxMidTime: number; // среди столов/ групп максимальное зарегистрированное время на опрос
    // usersMidTimesSum: number;

    usersMaxErrorsForQuestion: number; // максимальное количество ошибок на какой-либо вопрос

    questionsNo: number; // количество вопросов
    questionsWithCorrectAnswerNo: number; // количество вопросв с прописанным правильным ответом

    questionsFullyAnswered: number; // количество вопросов отвеченных всеми пользователями
    questionsFullyCorrectlyAnswered: number; // количество вопросов корректно отвеченных всеми пользователями

    tables: IQResultsTables; // перечень участвовавших столов
}

/**
 * Статистика ответов пользователей
 */
export interface IQResultsUserAnswers {
    total: number; // количество выданных ответов
    correct: number; // количество правильных ответов
    errors: number; // количество ошибочных ответов
    answerStats: { [key: string]: number }; // для каждого ответа общее количество так ответивших
}

/**
 * Статистика ответов для отдельного вопроса
 */
export interface IQResultsQuestionTotals {
    hasCorrectAnswer: boolean; // имеется ли правильный ответ
    variantsNo: number; // количество вариантов ответов
    duration?: {
        midTime: number; // средняя продолжительность раздумий над вопросом
    };
    // usersNo: number; //
    userAnswers?: IQResultsUserAnswers; // статистика ответов пользователей
}

/**
 * Статистика ответов на вопросы, сгруппированная по столам.
 */
export interface IQResultsPerTableTotals {
    [tableId: string]: IQResultsQuestionTotals[];
}
export interface IQResultsPerQuestionPerTableTotals {
    [tableId: string]: IQResultsUserAnswers;
}

/**
 * Полная статистика ответов на вопросы по всему проведенному опросу.
 * Добавлены два поля:
 *  - perTableTotals: группировка по столам
 *  - grandTotals: все ответы всех пользователей, посчитанные вместе
 */
export interface IQResultsAllQuestionTotals extends IQResultsQuestionTotals {
    perTableTotals: IQResultsPerQuestionPerTableTotals; // группировка по столам внутри каждого вопроса
    grandTotals: IQResultsUserAnswers; // все ответы всех пользователей, посчитанные вместе
}

/**
 * Статистика количества выбора правильных ответов по столам
 */
export interface IQResultsPerTablePoints {
    [tableId: string]: {
        correct: number;
        incorrect: number;
        total: number;
        perQuestion: {
            correct: number;
            incorrect: number;
            total: number;
        }[];
    };
}

/**
 * Общая структура со всей статистикой по проведенному опросу
 */
export interface IQResultsFullResults {
    quizTotals: IQResultsQuizTotals;
    perTableTotals: IQResultsPerTableTotals;
    perQuestionTotals: IQResultsAllQuestionTotals[];
    perTablePoints: IQResultsPerTablePoints;
}

export const emptyQuizRunResults: IQResultsFullResults = {
    quizTotals: {
        startTime: 0,
        finishTime: 0,
        usersStarted: 0,
        usersFinished: 0,
        usersMidTime: 0,
        usersMaxMidTime: 0,
        usersMaxErrorsForQuestion: 0,
        questionsNo: 0,
        questionsWithCorrectAnswerNo: 0,
        questionsFullyAnswered: 0,
        questionsFullyCorrectlyAnswered: 0,
        tables: {},
    },
    perTableTotals: {},
    perQuestionTotals: [],
    perTablePoints: {},
};

// @returns
//   results ~= {
//     quizTotals: {
//         startTime: 0,
//         finishTime: 0,
//         duration: 0,
//         usersStarted: 0,
//         usersFinished: 0,

//         questionsNo: 0,
//         questionsWithCorrectAnswerNo: 0,

//         questionsFullyAnswered: 0,
//         questionsFullyCorrectlyAnswered: 0,
//     },

//     tablesTotals: Map( tableId:            // Map of all tables affected
//         [{                                 // Array of questions as in the Quiz
//             duration: {
//                 midTime: 0,                // Sum of all durations / users num
//             },
//             hasCorrectAnswer: true,        // True if QUESTION in the Quiz has a correct answer as option
//             variantsNo: 0,                 // Total answers no

//             userAnswers: {                 // Cumulative counters for all users for table/ quesion

//                 total: 0,                  // Ideally = users num * questions num
//                 correct: 0,
//                 errors: 0,

//                 usersNo: 0,                // How many users took place in quiz at this table

//                 answerStats: {
//                     answerIndex: 0         // Per answer index counter
//                 }
//             }
//         }]
//     ),

//     questionsTotals: [{                    // Array of questions as in the Quiz
//         hasCorrectAnswer: true,            // True if QUESTION in the Quiz has a correct answer as option

//         grandTotals: Map( tableId: {      // Map of all tables affected

//             total: 0,                      //
//             correct: 0,
//             errors: 0,

//             answerStats: {
//                 [answerIndex]: 0           // Per answer index counter
//             }
//         }),

//         perTableTotals: Map( tableId: {    // Map of all tables affected

//             total: 0,                      //
//             correct: 0,
//             errors: 0,

//             answerStats: {
//                 [answerIndex]: 0           // Per answer index counter
//             }
//         })
//     }]
//

// }

function getDefaultUserAnswers(): IQResultsUserAnswers {
    return {
        total: 0,
        correct: 0,
        errors: 0,
        answerStats: {},
    };
}

export function questionHasCorrectAnswer(question: IQuizQuestion): boolean {
    if (question.questionType === IQuizQuestionTypes.Range) return false;

    const hasCorrectChoice =
        (question.questionType === IQuizQuestionTypes.SingleChoice ||
            question.questionType === IQuizQuestionTypes.MultiChoice) &&
        question.choices.some((choice: IQChoiceVariant) => choice.isCorrect);
    const hasCorrectFreeAnswer =
        question.questionType === IQuizQuestionTypes.FreeString &&
        question.correctFreeAnswers.some((str) => str.length > 0);
    return hasCorrectChoice || hasCorrectFreeAnswer;
}

export function answerIsCorrect(question: IQuizQuestion, answer: IQuizUserAnswer): boolean {
    if (isEmpty(answer.answers)) return false;

    switch (question.questionType) {
        case IQuizQuestionTypes.SingleChoice: {
            const correctChoiceId = question.choices.find((choice: IQChoiceVariant) => choice.isCorrect)?.id;
            return answer.answers[correctChoiceId ?? 0] === true;
        }
        case IQuizQuestionTypes.MultiChoice: {
            const correctChoiceIds = question.choices
                .filter((choice: IQChoiceVariant) => choice.isCorrect)
                .map((choice) => choice.id);
            const answerIds = Object.keys(answer.answers);
            return (
                correctChoiceIds.every((choiceId) => answerIds.includes(choiceId)) &&
                answerIds.length === correctChoiceIds.length
            );
        }
        case IQuizQuestionTypes.FreeString: {
            return question.correctFreeAnswers.reduce(
                (acc, currValue: string, index) =>
                    (acc && currValue === '') || answer.answers[`${index}`] === currValue,
                false
            );
        }
        case IQuizQuestionTypes.Range:
            return true;
    }
    return false;
}

export type TQuizUser = {
    userId: string;
    activity: IQuizRunUserActivity;
    location: IUserLocation;
};

/**
 * Рассчитываем статистику по вопросам для каждого конкретного стола/ группы
 * @param tableId
 * @param quizRun
 * @param quizUsers
 * @param quizTotals
 * @returns Array<IQResultsQuestionTotals>
 */
export function calcQuestionsForTableTotals(
    tableId: string,
    quizRun: IQuizRun,
    quizUsers: TQuizUser[],
    quizTotals: IQResultsQuizTotals
): Array<IQResultsQuestionTotals> {
    return quizRun.quiz.questions.map((question, qIndex): IQResultsQuestionTotals => {
        let qDuration = 0;

        const hasCorrectAnswer = questionHasCorrectAnswer(question);

        const userAnswers = getDefaultUserAnswers();

        const tableUsers = quizUsers.filter((user) => tableId === user.location.tableId);
        const tableUsersNo = tableUsers.length;

        tableUsers.forEach((user) => {
            const answer = user.activity?.answers?.[qIndex];
            const hasUserAnswer = !isEmpty(answer?.answers);

            if (hasUserAnswer) {
                userAnswers.total += 1;

                if (hasCorrectAnswer) {
                    if (answerIsCorrect(question, answer)) {
                        userAnswers.correct += 1;
                    } else {
                        userAnswers.errors += 1;
                    }
                }
                // инкрементируем для каждого данного варианта ответа общее количество так ответивших
                Object.keys(answer?.answers).forEach((answerId) => {
                    userAnswers.answerStats[answerId] = (userAnswers.answerStats[answerId] ?? 0) + 1;
                });
            }

            qDuration += Number(answer?.stats?.timeSpent ?? 0);
        });

        quizTotals.questionsFullyAnswered += userAnswers.total === tableUsersNo ? 1 : 0;
        quizTotals.questionsFullyCorrectlyAnswered += userAnswers.correct === tableUsersNo ? 1 : 0;

        const qMidTime = tableUsersNo === 0 ? 0 : qDuration / tableUsersNo;

        quizTotals.usersMaxMidTime = Math.max(qMidTime, quizTotals.usersMaxMidTime);

        quizTotals.usersMaxErrorsForQuestion = Math.max(
            quizTotals.usersMaxErrorsForQuestion,
            userAnswers.errors
        );

        return {
            hasCorrectAnswer,
            variantsNo: question.choices.length,
            duration: {
                midTime: qMidTime,
            },
            userAnswers,
        };
    });
}

const calcTablePoints = (quiz: IQuiz, tables: IQResultsTables, perQuestionTotals: IQResultsAllQuestionTotals[]) => {
    const result: IQResultsPerTablePoints = {};

    Object.keys(tables).forEach((tableId) => {
        result[tableId] = {
            correct: 0,
            incorrect: 0,
            total: 0,
            perQuestion: [],
        }
    })

    let correctHit = 0;
    let incorrectHit = 0;

    perQuestionTotals.forEach((qTotals, qIndex) => {
        Object.keys(qTotals.perTableTotals).forEach((tableId: string) => {
            const question = quiz.questions[qIndex];
            const hasCorrectAnswer = questionHasCorrectAnswer(question);
            question.choices.forEach((choice: IQChoiceVariant) => {
                const choiceNum = qTotals.perTableTotals?.[tableId]?.answerStats?.[choice.id] ?? 0;
                if (choice.isCorrect) {
                    correctHit += choiceNum;
                } else {
                    if (hasCorrectAnswer) incorrectHit += choiceNum;
                }
            });
            result[tableId].correct += correctHit;
            result[tableId].incorrect += incorrectHit;
            result[tableId].total += correctHit - incorrectHit;

            result[tableId].perQuestion[qIndex] = {
                correct: correctHit,
                incorrect: incorrectHit,
                total: (correctHit - incorrectHit)
            };

            correctHit = 0;
            incorrectHit = 0;
        });
    });
    return result;
};

/**
 * Функция расчета всей статистики про результатам проведенного опроса
 * @param quiz - опрос
 * @param quizRun - результаты проведения опроса
 * @param layout - рассадка по столам
 * @returns полную статистику IQResultsFullResults
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function calcQuizStats(quiz: IQuiz, quizRun: IQuizRun, layout?: any): IQResultsFullResults | null {
    if (isEmpty(quiz) || isEmpty(quizRun)) {
        return emptyQuizRunResults;
    }

    // собираем расширенную информацию по пользователям
    const quizUsers: TQuizUser[] = Object.keys(quizRun.users ?? {}).map((userId) => {
        return {
            userId,
            activity: quizRun.users[userId].activity as IQuizRunUserActivity, // надо присваивать именно так, чтобы срабатывали геттеры
            location: quizRun.users[userId].location as IUserLocation,
        };
    });

    const tables: IQResultsTables = {};

    // пробегаемся по пользователям и собираем информацию об имеющихся столах/ группах
    quizUsers.forEach((user) => {
        const found = user.location; // layout.findItemId(user.userId);

        const tableId = found.tableId ?? hostTableNumber;

        // если такого стола нет - добавляем. такое может быть в случае, если последний пользователь стола вышел до окончания опроса
        if (isNil(tables[tableId])) {
            tables[tableId] = {
                title: layout?.getTable(found.roomId, found.tableId)?.title ?? tableId,
                usersNo: 0,
            };
        }

        tables[tableId].usersNo += 1;
    });

    const quizTotals: IQResultsQuizTotals = {
        startTime: quizRun.stats?.startTime ?? 0,
        finishTime: quizRun.stats?.finishTime ?? 0,

        usersStarted: quizUsers.length,
        usersFinished: quizUsers.filter((user) => user.activity?.status == QuizRunUserStatuses.FINISHED)
            .length,
        usersMidTime:
            quizUsers.reduce(
                (accumulator, user) =>
                    accumulator +
                    calcDuration(user.activity?.stats?.startTime, user.activity?.stats?.finishTime),
                0
            ) / quizUsers.length,
        usersMaxMidTime: 0,

        usersMaxErrorsForQuestion: 0,

        questionsNo: quiz.questions.length,
        questionsWithCorrectAnswerNo: quiz.questions.filter((question) => questionHasCorrectAnswer(question))
            .length,

        questionsFullyAnswered: 0,
        questionsFullyCorrectlyAnswered: 0,

        tables,
    };

    quizTotals.duration = calcDuration(quizTotals.startTime, quizTotals.finishTime);

    // const tables = uniq(
    //     users
    //         .map(user => {
    //             let found = findItemsTable(user.userId);
    //             return (found.tableId ?? user.location.tableId)
    //         })
    //         .filter(tableId => !isNil(tableId)));

    const quizPerTableTotals: IQResultsPerTableTotals = {};
    Object.keys(tables).forEach((tableId) => {
        quizPerTableTotals[tableId] = calcQuestionsForTableTotals(tableId, quizRun, quizUsers, quizTotals);
    });

    function summator(objValue: never, srcValue: unknown) {
        if (isNumber(objValue)) {
            return (objValue ?? 0) + (srcValue ?? 0);
        }
    }

    const quizPerQuestionTotals = quiz.questions.map((question, questionIndex) => {
        let questionTotals = getDefaultUserAnswers();
        const questionPerTableTotals: IQResultsPerQuestionPerTableTotals = {};

        Object.keys(tables).forEach((tableId) => {
            questionTotals = mergeWith(
                questionTotals,
                quizPerTableTotals[tableId]?.[questionIndex]?.userAnswers,
                summator
            );
            questionPerTableTotals[tableId] = mergeWith(
                questionPerTableTotals[tableId],
                quizPerTableTotals[tableId]?.[questionIndex]?.userAnswers,
                summator
            );
        });

        return {
            hasCorrectAnswer: questionHasCorrectAnswer(question),
            variantsNo: question.choices.length,
            perTableTotals: questionPerTableTotals,
            grandTotals: questionTotals,
        } as IQResultsAllQuestionTotals;
    });

    const quizPerTablePoints = calcTablePoints(quiz, tables, quizPerQuestionTotals);

    logger.Debug('Quiz Totals: ', { quizTotals, quizPerTableTotals, quizPerQuestionTotals, quizPerTablePoints });
    return {
        quizTotals,
        perTableTotals: quizPerTableTotals,
        perQuestionTotals: quizPerQuestionTotals,
        perTablePoints: quizPerTablePoints,
    };
}
