import EventEmitter from 'eventemitter3';
import Logger from '../tsLogger';

const logger = Logger('EventActions');

class EventActionsEmitter extends EventEmitter {
    emit(type: any, ...args: any[]) {
        logger.Trace(`${type} emitted`, { ...args });
        return super.emit(type, ...args);
    }
}

export const EventActions = new EventActionsEmitter();
