import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ITariffCostFieldProps {
    text?: number;
}

/**
 * Компонент для отображения стоимости тарифа
 *
 * @param {number} text - Стоимость тарифа в рублях в месяц
 * @return {JSX.Element} Компонент TariffCostField
 */
export const TariffCostField: React.FC<ITariffCostFieldProps> = ({ text = 0 }) => {
    const { t } = useTranslation();
    return (
        <Typography
            variant="h5"
            sx={{
                textAlign: "center",
                margin: "4px 0 8px",
            }}
        >
            {text == 0 ? "Free" : `${text} ${t("R/mon")}`}
        </Typography>
    );
};
