import { EventActions } from '@tellsla/common';
import { DeepPartial } from './common';

export enum EventRunTimerCountDirection {
    UP = 'UP',
    DOWN = 'DOWN',
}

export enum EventRunTimerStatus {
    IDLE = 'IDLE',
    RUNNING = 'RUNNING',
    PAUSED = 'PAUSED',
    FINISHED = 'FINISHED',
}
export enum EventRunTimerCommand {
    UPSERT = 'UPSERT',
    TICKER_RUN = 'TICKER_RUN', // запускаем таймер. Переводим в статус RUNNING. Если переходили из статуса IDLE, то запускаем экшены по on.TimerStart
    TICKER_PAUSE = 'TICKER_PAUSE', // останавливаем таймер, переводим в статус PAUSED
    TICKER_FINISH = 'TICKER_FINISH', // останавливаем таймер, переводим в статус FINISHED. Запускаем экшены по on.TimerFinish
    TICKER_RESET = 'TICKER_RESET', // останавливаем таймер, переводим в статус IDLE
    TICKER_SET_MAX = 'TICKER_SET_MAX', // устанавливаем максимальное значение таймера
    // CHANGE_TIME = 'CHANGE_TIME',
    CHANGE_VISIBILITY = 'VISIBILITY',
    CHANGE_DIRECTION = 'DIRECTION',
}
export enum EventRunTimerEvent {
    TICK = 'TICK',
    TIMER_ACTION = 'TIMER_ACTION',
}

export enum EventRunTimerActionType {
    BEEP_ONCE = 'TIMER_ACTION_TYPE_BEEP_ONCE',
    BEEP_DOUBLE = 'TIMER_ACTION_TYPE_BEEP_DOUBLE',
    BEEP_TRIPLE = 'TIMER_ACTION_TYPE_BEEP_TRIPLE',
    MESSAGE = 'TIMER_ACTION_TYPE_MESSAGE',
    CHANGE_PAGE = 'TIMER_ACTION_TYPE_CHANGE_PAGE',
}

export interface IEventRunTimerAction {
    id: string;
    type: EventRunTimerActionType;
    data?: {
        message?: string;
        seconds?: number;
    };
}
export type EventRunTimerCommandEnvelope = {
    timerId: string;
    command: EventRunTimerCommand;
    data?: DeepPartial<IEventRunTimer>;
};

// export interface IEventRunTimerRepetitiveAction {
//     seconds: number;
//     actions: Array<IEventRunTimerAction>;
// }

export enum EventRunTimerType {
    SessionScheduler = 'SessionScheduler',
    General = 'General',
    CurrentPage = 'CurrentPage',
}

// Видимость таймера
export enum EventRunTimerVisibility {
    NONE = 'NONE', // Спрятан
    PERSONAL = 'PERSONAL', // Виден только владельцу
    GROUP = 'GROUP', // Виден только участникам группы
    GLOBAL = 'GLOBAL', // Виден всем
}

export enum TimerEffectId {
    TimerStart = 'timerStart',
    Every = 'timerEvery',
    MidTime = 'timerMidTime',
    AfterTimerStart = 'timerAfterStart',
    BeforeTimerFinish = 'timerBeforeFinish',
    TimerFinish = 'timerFinish',
}

export interface IEventRunTimerParams {
    displayFormat: {
        short: string;
        long: string;
    };
    minValue: number;
    maxValue: number;
    direction: EventRunTimerCountDirection;
    on: {
        [TimerEffectId.TimerStart]?: Array<IEventRunTimerAction>;
        [TimerEffectId.AfterTimerStart]?: Array<IEventRunTimerAction>;
        [TimerEffectId.Every]?: Array<IEventRunTimerAction>;
        [TimerEffectId.MidTime]?: Array<IEventRunTimerAction>;
        [TimerEffectId.BeforeTimerFinish]?: Array<IEventRunTimerAction>;
        [TimerEffectId.TimerFinish]?: Array<IEventRunTimerAction>;
    };
}

export interface IEventRunTimer {
    id: string;
    type: EventRunTimerType;
    params: IEventRunTimerParams;
    status: EventRunTimerStatus;
    visibility: EventRunTimerVisibility;
    tickCount: number;
    duration: number;
    lastRunTimeStamp: number;
    lastRunTickCount: number;
}

export class EventRunTimer {
    id: string; // id таймера
    type: EventRunTimerType; // тип таймера
    params: IEventRunTimerParams;
    status: EventRunTimerStatus;
    visibility: EventRunTimerVisibility; // видимость таймера
    private tickerId: any = null; // идентификатор тикающего таймера
    protected tickCount: number = -1;
    lastRunTimeStamp: number = -1;
    lastRunTickCount: number = 0;
    duration: number = -1;

    constructor(data: IEventRunTimer) {
        this.id = data.id;
        this.type = data.type;
        this.params = { ...data.params };
        this.visibility = data.visibility;
        this.status = data.status;
        this.lastRunTimeStamp = data.lastRunTimeStamp ?? -1;
        this.lastRunTickCount = data.lastRunTickCount ?? 0;
        this.tickCount = data.tickCount ?? 0;
        this.resetDuration();
        if (this.status === EventRunTimerStatus.RUNNING) {
            this.runTicker();
        }
    }

    updateTikerCountToTimestamp(currentTimestamp: number) {
        if (this.lastRunTimeStamp === -1) return;

        const timeDiff = currentTimestamp - this.lastRunTimeStamp;

        const calcedTickCount = this.lastRunTickCount + Math.floor(timeDiff / 1000);

        if (calcedTickCount > this.tickCount) {
            this.tickCount = calcedTickCount;
        }
    }

    // Функция, вызываемая на каждую секунду таймера
    private tickerFunction() {
        this.tickCount += 1;

        // если мы достигли окончания длительности таймера, то завершаем финализируем таймер
        // duration может быть null в случае таймера без ограничения длительности
        if (this.duration >= 0 && this.tickCount >= this.duration) {
            this.stopTicker();
            this.finishTimer();
            return;
        }

        this.emitTimerEvent(EventRunTimerEvent.TICK, {
            id: this.id,
            tickCount: this.tickCount,
        });

        // отработка экшенов on.beforeTimerFinish
        const onAfterTimerStart = this.params.on[TimerEffectId.AfterTimerStart];
        if (onAfterTimerStart && onAfterTimerStart.length > 0) {
            onAfterTimerStart.forEach((action) => {
                const seconds = action.data?.seconds ?? -1;
                if (seconds > 0 && this.tickCount === seconds) {
                    this.emitTimerAction(action);
                }
            });
        }

        // отработка экшенов on.every
        const onEvery = this.params.on[TimerEffectId.Every];
        if (onEvery) {
            onEvery.forEach((action) => {
                const seconds = action.data?.seconds ?? -1;
                if (seconds > 0 && this.tickCount % seconds === 0) {
                    this.emitTimerAction(action);
                }
            });
        }

        // если duration не задан, то заканчиваем тик, т.к. дальше мы будем пробовать делать midTime и beforeTimerFinish
        if (this.duration <= 0) return;

        // отработка экшенов on.midTime
        const onMidTime = this.params.on[TimerEffectId.MidTime];
        if (onMidTime && this.tickCount >= 2) {
            onMidTime.forEach((action) => {
                if (this.tickCount === Math.floor((this.duration ?? 0) / 2)) {
                    this.emitTimerAction(action);
                }
            });
        }

        // отработка экшенов on.beforeTimerFinish
        const onBeforeTimerFinish = this.params.on[TimerEffectId.BeforeTimerFinish];
        if (onBeforeTimerFinish) {
            onBeforeTimerFinish.forEach((action) => {
                const seconds = action.data?.seconds ?? -1;
                if (seconds > 0 && this.tickCount === this.duration - seconds) {
                    this.emitTimerAction(action);
                }
            });
        }
    }

    runTicker() {
        if (this.tickerId === null) {
            this.tickerId = setInterval(this.tickerFunction.bind(this), 1000);
        }
    }
    stopTicker() {
        if (this.tickerId !== null) {
            clearInterval(this.tickerId);
            this.tickerId = null;
        }
    }

    applyTimeEvent(event: EventRunTimerCommand, value?: number) {
        if (!event) return;
        switch (event) {
            case EventRunTimerCommand.TICKER_RUN:
                this.runTimer(value);
                break;
            case EventRunTimerCommand.TICKER_PAUSE:
                this.pauseTimer(value);
                break;
            case EventRunTimerCommand.TICKER_FINISH:
                this.finishTimer();
                break;
            case EventRunTimerCommand.TICKER_RESET:
                this.resetTimer();
                break;
            case EventRunTimerCommand.TICKER_SET_MAX:
                if (typeof value !== 'number') return;
                this.setMaxValue(value);
                break;
        }
    }

    runTimer(tickCount = this.tickCount) {
        if (this.status === EventRunTimerStatus.IDLE) {
            // отработка экшенов on.TimerStart
            const onTimerStart = this.params.on[TimerEffectId.TimerStart];
            if (onTimerStart) {
                onTimerStart.forEach((action) => {
                    this.emitTimerAction(action);
                });
            }
        }
        // запуск таймера
        this.status = EventRunTimerStatus.RUNNING;
        if (this.lastRunTimeStamp !== -1) {
            const calcedTickCount = Math.round((Date.now() - this.lastRunTimeStamp - 1000) / 1000);
            if (this.tickCount < calcedTickCount) {
                this.tickCount = calcedTickCount;
            }
        } else {
            this.tickCount = tickCount;
        }
        this.lastRunTickCount = this.tickCount;
        this.lastRunTimeStamp = Date.now();
        this.runTicker();
    }
    pauseTimer(tickCount = this.tickCount) {
        // можно останавливать только если таймер запущен
        if (this.status === EventRunTimerStatus.RUNNING) {
            // остановка таймера
            this.status = EventRunTimerStatus.PAUSED;
        }
        this.stopTicker();
        this.lastRunTickCount = 0;
        this.lastRunTimeStamp = -1;
        this.tickCount = tickCount;
        return this.tickCount;
    }
    finishTimer() {
        this.stopTicker();
        if (this.status === EventRunTimerStatus.RUNNING) {
            // отработка экшенов on.TimerFinish
            const onTimerFinish = this.params.on[TimerEffectId.TimerFinish];
            if (onTimerFinish) {
                onTimerFinish.forEach((action) => {
                    this.emitTimerAction(action);
                });
            }
        }
        // завершение таймера
        this.status = EventRunTimerStatus.FINISHED;
        this.tickCount = this.params.maxValue ?? 0;
        this.lastRunTickCount = 0;
        this.lastRunTimeStamp = -1;
        this.emitTimerCommand(EventRunTimerCommand.TICKER_FINISH, {
            command: EventRunTimerCommand.TICKER_FINISH,
            timerId: this.id,
        });
    }
    resetTimer() {
        // сброс таймера
        // this.telemetry.startTimestamp = null;
        // this.telemetry.value = null;
        this.stopTicker();
        this.tickCount = 0;
        this.status = EventRunTimerStatus.IDLE;
        this.lastRunTickCount = 0;
        this.lastRunTimeStamp = -1;
    }
    public set value(value: number) {
        if (value === null || value === undefined) return;
        this.tickCount = value;
    }
    public get value(): number {
        return this.tickCount;
    }
    setMinValue(value: number) {
        this.params.minValue = value;
        this.resetDuration();
    }
    setMaxValue(value: number) {
        if (
            typeof value === 'number' &&
            value <= this.tickCount &&
            this.status === EventRunTimerStatus.RUNNING
        ) {
            return false;
        }
        this.params.maxValue = value;
        this.resetDuration();
        return true;
    }
    setVisibility(visibility: EventRunTimerVisibility) {
        if (visibility === null || visibility === undefined) return;
        this.visibility = visibility;
    }
    setDirection(direction: EventRunTimerCountDirection) {
        if (direction === null || direction === undefined) return;
        this.params.direction = direction;
    }
    public get startValue() {
        return this.params.direction === EventRunTimerCountDirection.DOWN
            ? this.params.maxValue
            : this.params.minValue;
    }
    private resetDuration() {
        if (
            typeof this.params.maxValue == 'number' &&
            typeof this.params.minValue == 'number' &&
            this.params.maxValue >= 0
        ) {
            this.duration = Math.abs(this.params.maxValue - this.params.minValue);
        } else {
            this.duration = -1;
        }
    }
    protected emitTimerEvent(event: EventRunTimerEvent, data?: DeepPartial<IEventRunTimer>) {
        return EventActions.emit(event, data);
    }
    protected emitTimerCommand(command: EventRunTimerCommand, envelope?: EventRunTimerCommandEnvelope) {
        return EventActions.emit(command, envelope);
    }
    protected emitTimerAction(data: IEventRunTimerAction) {
        return EventActions.emit(EventRunTimerEvent.TIMER_ACTION, data);
    }
    toJSON(): IEventRunTimer {
        return {
            id: this.id,
            type: this.type,
            params: this.params,
            visibility: this.visibility,
            status: this.status,
            tickCount: this.tickCount,
            duration: this.duration,
            lastRunTickCount: this.lastRunTickCount,
            lastRunTimeStamp: this.lastRunTimeStamp,
        };
    }
}

interface IEventTimerOnPageActions {
    enter?: Array<IEventRunTimerAction>;
    exit?: Array<IEventRunTimerAction>;
}
export interface IEventRunPageTimer extends IEventRunTimer {
    onPage?: IEventTimerOnPageActions;
}

export class EventRunPageTimer extends EventRunTimer {
    onPage?: IEventTimerOnPageActions;

    constructor(data: IEventRunTimer & { onPage?: IEventTimerOnPageActions }) {
        super(data);
        this.onPage = data.onPage;
    }

    toJSON(): IEventRunPageTimer {
        const json: IEventRunPageTimer = super.toJSON();
        json.onPage = this.onPage;
        return json;
    }
}

export interface IEventRunTimers {
    [id: string]: EventRunTimer;
}
