/* eslint-disable no-console */
import { isError, isNil } from 'lodash';
import { SharedProps } from 'notistack';
import NotifycationEmitter from './NotifycationEmitter';

export const MSG_UI_NOTIFICATION = 'MSG_UI_NOTIFICATION';

export function showNotification(message: string, options?: SharedProps) {
    const notifyOptions = { ...options };
    if (isNil(options?.variant)) { notifyOptions.variant = 'info'; }

    if (isNil(options?.preventDuplicate)) { notifyOptions.preventDuplicate = true; }

    const text = `Notify: ${options?.variant}, ${message}`;

    if (options?.variant === 'error') {
        console.error(text);
    } else {
        console.log(text);
    }

    NotifycationEmitter.emit(MSG_UI_NOTIFICATION, { message: String(message), options });
}

export function notifyInfo(message: string) { showNotification(message, { variant: 'info' }); }
export function notify(message: string) { notifyInfo(message); }
export function notifySuccess(message: string) { showNotification(message, { variant: 'success' }); }
export function notifyWarning(message: string | Error) {
    console.error('notifyWarning', message);
    showNotification(isError(message) ? message.message : message, { variant: 'warning' });
}
export function notifyError(message?: Error | string) {
    showNotification(isError(message) ? message.message : message ?? 'Internal error', { variant: 'error' });
}
