import { Logger, TLogger } from '@tellsla/common';
import { config } from '@tellsla/config';
import axios from 'axios';
import { baseApiUrl, IFrontHostConfig } from './urls';
import { ILogRecord } from '@tellsla/serverTypes';

const logger = Logger('restApi');

axios.defaults.timeoutErrorMessage = 'server timeout';

/**
 * Оберточная функция, создающая стандартное axiox-обращение на серевер
 * @returns
 */
export const restAPI = () =>
    axios.create({
        baseURL: `${baseApiUrl(config.backendHost as IFrontHostConfig)}${config.backendHost.restApi.path}`,
        timeout: config.backendHost.timeout,
        responseType: 'json',
        headers: config.backendHost.restApi.headers,
    });

export const { CancelToken } = axios;

/**
 * Выставляем / запоминаем полученный на сервере токен для всех последующих запросов.
 * @param token само значение токена в виде base64 строки. При передаче значения false или null токен будет удален.
 */
export function setHttpAuthToken(token: string) {
    if (token) {
        logger.Debug('HTTP Auth token set');
        // Apply authorization token to every request if logged in
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        logger.Debug('HTTP Auth token deleted');
        // Delete auth header
        delete axios.defaults.headers.common.Authorization;
    }
}

interface ICurrentSession {
    sessionId: string | null;
    userId: string | null;

    [key: string]: any;
}

const currentSession: ICurrentSession = {
    sessionId: null,
    userId: null,
};

function sessionSetOption(key: string, value: string | null) {
    currentSession[key] = value;
}
export function sessionSetSessionId(sessionId: string) {
    sessionSetOption('sessionId', sessionId);
}
export function sessionResetSessionId() {
    sessionSetOption('sessionId', null);
}
export function sessionSetUserId(userId: string) {
    sessionSetOption('userId', userId);
}
export function sessionResetUserId() {
    sessionSetOption('userId', null);
}
export function sessionResetAll() {
    Object.keys(currentSession).forEach((key) => {
        sessionSetOption(key, null);
    });
}
export function addHTTPTransport(logger: TLogger) {
    logger.logger.attachTransport((logObj) => {
        const payload: Array<any> = [];
        if (logObj[1]) {
            Object.keys(logObj).forEach((key) => {
                if (Number(key) > 0) payload.push(logObj[+key]);
            });
        }

        const logMsg: ILogRecord = {
            level: logObj._meta.logLevelId,
            message: String(logObj[0]) ?? '',
            payload,
            sessionId: currentSession.sessionId ?? '',
            timestamp: Date.now(),
            userId: currentSession.userId ?? '',
            nsp: logObj._meta.name ?? '',
            runtime: logObj._meta.runtime ?? '',
            // logObj,
        };
        // console.log('HTTP transport: ', { logObj, logMsg });
        restAPI().post('/log', logMsg);
    });
}

export * from './urls';
