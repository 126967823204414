import React from 'react';
import { Box, SxProps, styled } from '@mui/material';
import { config } from '@tellsla/config';

const WithRatioWrapper = styled(Box)({
    display: 'grid',
    width: '100%',
    '& > *': {
        gridArea: '1/1',
    },
});

const SoftFrame = styled('div')({
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
});

const HardFrame = styled(Box)({
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
});

interface IProps {
    aspectRatio?: number;
    className?: string;
    sx?: SxProps;
    innerSx?: SxProps;
    children?: React.ReactNode;
}

export const WithRatio: React.FunctionComponent<IProps> = ({
    aspectRatio = config.defaults.mediaAspectRatio,
    className = '',
    sx,
    innerSx,
    children,
}): React.ReactElement => {
    return (
        <WithRatioWrapper className={className} sx={sx}>
            <svg viewBox={`0 0 ${aspectRatio} 1`} />
            <SoftFrame>
                <HardFrame sx={innerSx}>{children}</HardFrame>
            </SoftFrame>
        </WithRatioWrapper>
    );
};
