import {
    Button,
    styled,
} from '@mui/material';

export const TariffPriceButton = styled(Button)({
    width: "100%",
    margin: "8px 0",
    color: "white",
    borderRadius: "10px",
})