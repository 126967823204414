import { Stack, styled } from "@mui/material";
import React from "react";
import { IQResultsFullResults } from "@tellsla/common";
import { IQuizQuestion, IQuizUserAnswer } from "@tellsla/serverTypes";
import { QuizReportQuestionTbl } from "./QuizReportQuestionTbl";
import { QuizReportQuestionGroupTotalPoints } from "./QuizReportQuestionGroupTotalPoints";

export const Root = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    alignSelf: "start",
    width: "100%",
}));

export interface QuizResultsTotalsPerQuestionProps {
    questions: Array<IQuizQuestion>;
    selfAnswers: Array<IQuizUserAnswer>;
    results: IQResultsFullResults;
}

export const QuizResultsTotalsPerQuestion: React.FC<
    QuizResultsTotalsPerQuestionProps
> = ({ questions, selfAnswers, results }) => {
    return (
        <Root>
            <Stack direction="column">
                {(questions ?? []).map((question, qIndex) => (
                    <QuizReportQuestionTbl
                        key={`qrqt-${qIndex}`}
                        results={results}
                        questionIndex={qIndex}
                        question={questions[qIndex]}
                        selfAnswer={selfAnswers[qIndex]}
                    />
                ))}
                <QuizReportQuestionGroupTotalPoints results={results} />
            </Stack>
        </Root>
    );
};
