import React from 'react';

export interface IVideoPlayerControlPanelContext {
    extendedControlPanel: boolean;
    controlPanelIsOpen: boolean;
}

export const VideoPlayerControlPanelContext = React.createContext<IVideoPlayerControlPanelContext>({
    extendedControlPanel: false,
    controlPanelIsOpen: false,
});