import { IPlaybookPage } from '@tellsla/serverTypes';
import React from 'react';

interface IPlaybookPresenterContext {
    currentPageIndex?: number;
    page: IPlaybookPage | null;
    forcePreview: boolean;
    increaseActiveBookPage: () => void;
    decreaseActiveBookPage: () => void;
}
export const PlaybookPresenterContext = React.createContext<IPlaybookPresenterContext>({
    currentPageIndex: undefined,
    page: null,
    forcePreview: true,
    increaseActiveBookPage: () => {},
    decreaseActiveBookPage: () => {},
});
