export enum WSCommands {
    Authorize = 'authorize',
    JoinEvent = 'joinEvent',
    // ExitRoom = 'exitRoom',
    // Disconnect = 'disconnect',
    Logout = 'logout',
    RaiseHand = 'raisedHand',
    ExpressEmoji = 'expressEmoji',
    MicIsOn = 'micIsOn',
    UpdateToken = 'updateToken',
    // ChangeUser = 'changeUser',
    TimeLineAppend = 'changeTimeline',
    ExpositionUpdate = 'changeExposition',
    RoomUpdate = 'changeRoom',
    TableUpdate = 'changeTable',
    TableIsolate = 'isolateTable',
    TableUnite = 'uniteTable',
    TablesIsolateAll = 'isolateAllTables',
    TablesUniteAll = 'uniteAllTables',
    TableListening = 'startListenToTable',
    PlaybookCommand = 'playbookCommand',
    PlaybookGet = 'getPlaybook',
    PlaybookTelemetry = 'playbookTelemetry',
    // TimerCommand = 'timerCommand',
    MediaCommand = 'mediaCommand',
    QuizRunCommand = 'quizRunCommand',
    QuizRunGet = 'quizRunGet',
    QuizRunActivity = 'quizRunActivity',
    MSGetProducers = 'getProducers',
    MSCreateWebRtcTransport = 'createWebRtcTransport',
    MSTransportConnect = 'connectTransport',
    MSTransportProduce = 'transport-produce',
    MSConsumerResume = 'resumeConsumer',
    MSProducerClosed = 'producerClosed',
    UserListCommand = 'userListCommand',
    ChatMessage = 'chatmessage',
    UserChangeAppFocus = 'changeAppFocus',
    MediaStatsGet = 'getMediaStats',
    RelocateUsers = 'relocateUsersToRoom',
    EventRun = 'eventRunCommand',
    TimerCommand = 'timerCommand',
    Layout = 'changeL2',

};

export enum WSCEventRunCommand {
    SET_LOCK = 'SET_LOCK',
    TIMER_COMMAND = 'TIMER_COMMAND',
    START_EVENT = 'START_EVENT',
    EVENT_STARTED = 'EVENT_STARTED',

    FINISH_EVENT = 'FINISH_EVENT',
    EVENT_CLOSED = 'EVENT_CLOSED',

    CHAT_RESET = 'CHAT_RESET',
}
