import React from "react";
import { Box } from "@mui/material";
import { TariffField } from "./TariffField";
import { useTranslation } from "react-i18next";
import { ITariffParams } from "./TariffInfoBoard";

interface TariffArrayShowProps {
    array: Array<ITariffParams>;
}

export const TariffArrayShow: React.FC<TariffArrayShowProps> = ({ array }) => {
    const { t } = useTranslation();
    return (
        <Box>
            {array.map((param, keyS) => (
                <TariffField key={keyS}>
                    {t(param.key)}: {t(param.value)}
                </TariffField>
            ))}
        </Box>
    );
};
