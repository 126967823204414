import {
    Card,
    styled,
} from '@mui/material';

export const TariffCard = styled(Card)({
    minWidth: "200px",
    // maxWidth: "240px",
    width: "30%",
    padding: "16px 8px",
    margin: "8px",
    borderRadius: "10px",
})