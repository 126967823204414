import React, { useEffect, useRef } from 'react';

import { styled } from '@mui/material';
import { isNil } from 'lodash';

import scrollIntoView from 'scroll-into-view-if-needed';

const MaximizedDiv = styled('div')({
    width: '100%',
    height: '100%',
});

interface IProps {
    isSelected?: boolean;
    children?: React.ReactNode;
}

export const SelfScroll: React.FC<IProps> = ({ isSelected, children }) => {
    const boundary = document.getElementById('pedit-pages-grid-wrapper');
    const wrapperViewRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isSelected) {
            setTimeout(() => {
                // console.log('tick');
                if (!isNil(wrapperViewRef.current)) {
                    scrollIntoView(wrapperViewRef.current, { behavior: 'smooth', block: 'center', boundary });
                }
            }, 0);
            wrapperViewRef.current?.focus();
        }

        return () => {};
    });

    return <MaximizedDiv ref={wrapperViewRef}>{children}</MaximizedDiv>;
};
