import ExpiredStorage from 'expired-storage';
import { isNil } from 'lodash-es';
import { config } from './clientConfig';

export interface IOptions {
    [key: string]: unknown;
}

// create a custom storage class for testing
const testStorage = {
    _storage: {},
    getItem: function (key: string) {
        const ret = (this._storage as IOptions)[key];
        if (isNil(ret)) return null;
        return ret;
    },
    setItem: function (key: string, val: unknown) {
        (this._storage as IOptions)[key] = val;
    },
    removeItem: function (key: string) {
        delete (this._storage as IOptions)[key];
    },
    clear: function () {
        this._storage = {};
    },
    keys: function () {
        const ret = [];
        for (const key in this._storage) {
            // eslint-disable-next-line no-prototype-builtins
            if (this._storage.hasOwnProperty(key)) {
                ret.push(key);
            }
        }
        return ret;
    },
};

export const expiredStorage = new ExpiredStorage(
    typeof localStorage !== 'undefined' ? localStorage : testStorage
);

// TODO: Сделать функцию вычищания станых записей в expiredStorage
/**
 * Возвращает имя файла в local-storage, в котором надо искать токен для Сессии
 * @param eventRunId идентификатор Сессии
 * @returns строку с именем фаайла
 */
export const getEventTokenFileName = (eventRunId: string): string =>
    `${eventRunId}-${config.eventTokenFileName}`;

/**
 * Возвращает имя файла в local-storage, в котором надо искать токен для Пользователя
 * @returns строку с именем фаайла
 */
export const getUserTokenFileName = (eventRunId?: string) =>
    eventRunId ? `${eventRunId}-${config.adminTokenFileName}` : config.adminTokenFileName;

/**
 * Сохраняет токен сессии в local-storage
 * @param eventRunId идентификатор Сессии
 * @param token токен доступа на Сессию
 * @returns значение токена или null
 */
export const setEventStoredToken = (eventRunId: string, token: string) =>
    isNil(eventRunId)
        ? null
        : expiredStorage.setItem(getEventTokenFileName(eventRunId), token, config.eventTokenExpiresIn);

/**
 * Достает токен из local-storage, если таковой сохранялся именно для Сессии
 * @param eventRunId идентификатор Сессии
 * @returns значение токена или null
 */
export const getEventStoredToken = (eventRunId: string) =>
    isNil(eventRunId) ? null : expiredStorage.getItem(getEventTokenFileName(eventRunId));

/**
 * Удаляет из local-storage сохраненный токен для Сессии
 */
export const removeEventStoredToken = (eventRunId: string) =>
    isNil(eventRunId) ? null : expiredStorage.removeItem(getEventTokenFileName(eventRunId));

/**
 * Сохраняет пользовательский токен в local-storage.
 * @returns значение токена или null
 */
export const setUserStoredToken = (token: string, eventRunId?: string) =>
    expiredStorage.setItem(getUserTokenFileName(eventRunId), token, config.userTokenExpiresIn);

/**
 * Достает токен из local-storage, если таковой сохранялся для пользователя.
 * Для пользователя токен сохраняется при входе в личный кабинет или при логине на Сессию
 * @returns значение токена или null
 */
export const getUserStoredToken = (eventRunId?: string) =>
    expiredStorage.getItem(getUserTokenFileName(eventRunId));

/**
 * Удаляет из local-storage сохраненный токен для Пользователя
 */
export const removeUserStoredToken = (eventRunId?: string) =>
    expiredStorage.removeItem(getUserTokenFileName(eventRunId));
