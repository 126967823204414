import { Logger } from '@tellsla/common';
import { config } from '@tellsla/config';
import Bowser from 'bowser';
import enumerateDevices from 'enumerate-devices';
import { isError, isNil } from 'lodash';
import { Device } from 'mediasoup-client';

export const logger = Logger('Utils.Media');

export type TMediaDeviceParam = {
    deviceId: string;
    label?: string;
    on: boolean;
};

export type TMediaDevicesConfig = {
    cam: TMediaDeviceParam;
    mic: TMediaDeviceParam;
    spk: TMediaDeviceParam;
};

export type TAvailableMediaDevices = {
    cam: Array<TMediaDeviceParam>;
    mic: Array<TMediaDeviceParam>;
    spk: Array<TMediaDeviceParam>;
};

export const browser = Bowser.getParser(window.navigator.userAgent);
export const isFirefox = browser.satisfies({ firefox: '>31' });

export const defaultMediaDeviceId = '';
export const defaultMediaDeviceLabel = '';

interface ICheckMediaConstraints {
    audio: boolean;
    video: boolean;
}

export interface ICurrentMediaConstraint {
    deviceAvailable: boolean;
    diagnosticMessage: string | null;
}
export interface ICurrentMediaConstraints {
    audio: ICurrentMediaConstraint;
    video: ICurrentMediaConstraint;
}
export const initialMediaConstraints: ICurrentMediaConstraints = {
    audio: {
        deviceAvailable: false,
        diagnosticMessage: 'Not available',
    },
    video: {
        deviceAvailable: false,
        diagnosticMessage: 'Not available',
    },
};
export const currentMediaConstraints: ICurrentMediaConstraints = {
    ...initialMediaConstraints,
};

function setCurrentMediaConstrant(kind, constraint) {
    currentMediaConstraints[kind] = constraint;
}
function setAudioDeviceAvailable(available: boolean, diagnosticMessage?: string) {
    setCurrentMediaConstrant('audio', {
        deviceAvailable: available,
        diagnosticMessage: available ? null : diagnosticMessage ?? 'No audio devices available',
    });
}
function setVideoDeviceAvailable(available: boolean, diagnosticMessage?: string) {
    setCurrentMediaConstrant('video', {
        deviceAvailable: available,
        diagnosticMessage: available ? null : diagnosticMessage ?? 'No video devices available',
    });
}
function resetMediaDeviceAvailability(constraints?: ICheckMediaConstraints, message = null) {
    if (isNil(constraints) || constraints.audio === true) {
        setAudioDeviceAvailable(false, message);
    }
    if (isNil(constraints) || constraints.video === true) {
        setVideoDeviceAvailable(false, message);
    }
}
export const currentMediaDevices: any = {
    mic: [],
    cam: [],
    spk: [],
};
const getSafeUserMediaConstraintsFromDevices = (devices: Array<{ kind: string }>) => {
    const constraints: ICheckMediaConstraints = {
        audio: false,
        video: false,
    };

    devices.forEach((device) => {
        switch (device.kind) {
            case 'audioinput':
                constraints.audio = true;
                break;
            case 'videoinput':
                constraints.video = true;
                break;
        }
    });

    return constraints;
};

export function getMediaErrorDescription(error) {
    switch (error?.name) {
        case 'AbortError':
            return 'Device could not be acquired';
        case 'InvalidStateError':
            return 'Device is not available at the moment';
        case 'NotAllowedError':
            return 'Access to device not allowed';
        case 'NotFoundError':
            return 'No devices found';
        case 'OverconstrainedError':
            return 'No compatible devices found';
        case 'SecurityError':
            return 'Media support is disabled';
        default:
            return 'Device unavailable';
    }
}

export async function getAllMediaDevices(): Promise<TAvailableMediaDevices> {
    return new Promise((resolve, reject) => {
        function grabDevices(devices: Array<MediaDeviceInfo> | Error) {
            const newMediaDevices: TAvailableMediaDevices = {
                mic: [],
                cam: [],
                spk: [],
            };

            if (isError(devices)) {
                reject(devices);
                return;
            }

            logger.Debug('Got full list of media devices: ', { devices });

            function sanitizeDeviceInfo(device): TMediaDeviceParam {
                return {
                    deviceId: device.deviceId,
                    label: device.label,
                    on: false,
                };
            }

            devices.forEach((device) => {
                switch (device.kind) {
                    case 'audioinput':
                        newMediaDevices.mic.push(sanitizeDeviceInfo(device));
                        setAudioDeviceAvailable(true);
                        break;
                    case 'videoinput':
                        newMediaDevices.cam.push(sanitizeDeviceInfo(device));
                        setVideoDeviceAvailable(true);
                        break;
                    case 'audiooutput':
                        newMediaDevices.spk.push(sanitizeDeviceInfo(device));
                        break;
                    default:
                        break;
                }
            });

            if (newMediaDevices.spk.length === 0) {
                newMediaDevices.spk.push({
                    deviceId: 'default',
                    label: 'Default device',
                    on: false,
                });
            }

            resolve(newMediaDevices);
        }

        enumerateDevices()
            .then((devices) => {
                const firstDeviceLabel = devices?.[0]?.label;

                if (isNil(firstDeviceLabel) || firstDeviceLabel === '') {
                    const constraints = getSafeUserMediaConstraintsFromDevices(devices);

                    if (isNil(navigator.mediaDevices) || (!constraints.audio && !constraints.video)) {
                        resetMediaDeviceAvailability(null, 'No media devices available');
                        reject(new Error('No media devices available'));
                        return;
                    }

                    navigator.mediaDevices
                        .getUserMedia(constraints)
                        .then((medias) => {
                            try {
                                enumerateDevices()
                                    .then(grabDevices)
                                    .catch((error) => {
                                        logger.Err(`Error on enumerateDevices: ${error.message}`);
                                        reject(error);
                                    });
                            } catch (error) {
                                logger.Err(`Error on enumerateDevices: ${error.message}`);
                                reject(error);
                            } finally {
                                medias.getTracks().forEach((track) => {
                                    track.stop();
                                });
                            }
                        })
                        .catch((error) => {
                            resetMediaDeviceAvailability(constraints, getMediaErrorDescription(error));
                            logger.Err('Error on getUserMedia', error);
                            reject(error);
                        });
                    if (!constraints.audio || !constraints.video) {
                        navigator.mediaDevices
                            .getUserMedia({
                                audio: !constraints.audio,
                                video: !constraints.video,
                            })
                            .catch((error) => {
                                const deviceAvailabilitySetter = !constraints.audio
                                    ? setAudioDeviceAvailable
                                    : setVideoDeviceAvailable;
                                deviceAvailabilitySetter(false, getMediaErrorDescription(error));
                            });
                    }
                } else {
                    grabDevices(devices);
                }
            })
            .catch((error) => {
                logger.Err(`Error on getAllMediaDevices: ${error.message}`);
                resetMediaDeviceAvailability(null, getMediaErrorDescription(error));
                reject(error);
            });
    });
}

function updateCurrentMediaConfig() {
    getAllMediaDevices()
        .then((devices) => {
            currentMediaDevices.mic = devices?.mic ?? [];
            currentMediaDevices.cam = devices?.cam ?? [];
            currentMediaDevices.spk = devices?.spk ?? [];
        })
        .catch((error) => {});
}

addEventListener('devicechange', getAllMediaDevices);

export function getDefaultMediaDevice(devKind: string, newAvailableMediaDevices: TAvailableMediaDevices) {
    const availableMediaDevice = newAvailableMediaDevices[devKind]?.[0] ?? {};
    return {
        deviceId: availableMediaDevice.deviceId ?? defaultMediaDeviceId,
        label: availableMediaDevice.label ?? defaultMediaDeviceLabel,
        on: false,
    };
}

/**
 *
 * @param {Object} stream MediaStream object
 * @param {String} kind 'audio' | 'video' | 'screen'
 * @returns {String} deviceId used in the stream
 */
export function getDeviceIdFromStream(stream: MediaStream, kind: string): string | undefined {
    if (isNil(stream) || isNil(kind)) {
        return null;
    }

    const track = kind === 'audio' ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0];

    return track?.getSettings()?.deviceId;
}

/**
 * Получаем из медиа трека максимальную ширину картинки
 * @param track медиа трек
 * @returns максимальную ширину картинки
 */
export function getCamMaxWidth(track: MediaStreamTrack): number | undefined {
    return isFirefox ? track?.getSettings().width : track?.getCapabilities().width?.max;
}

export function getRtpEncodings(device: Device, track: MediaStreamTrack, trackType: string) {
    // If VP9 is the only available video codec then use SVC.
    const firstVideoCodec = (device?.rtpCapabilities?.codecs ?? []).find((c) => c.kind === 'video');

    let isVp9Track = false;
    if (firstVideoCodec?.mimeType?.toLowerCase() === 'video/vp9') {
        isVp9Track = true;
    }

    switch (trackType) {
        case 'video': {
            if (isVp9Track) return config.WEBCAM_KSVC_ENCODINGS;

            const camMaxWidth = getCamMaxWidth(track);
            logger.Trace(`Got max width for media track: ${camMaxWidth}`);
            if (camMaxWidth < 720) {
                return config.CAM_VIDEO_ENCODINGS_VGA;
            }
            if (camMaxWidth < 1080 && camMaxWidth >= 720) {
                return config.CAM_VIDEO_ENCODINGS_HD;
            }
            return config.CAM_VIDEO_ENCODINGS_FHD;
        }

        case 'screen':
            if (isVp9Track) return config.SCREEN_SHARING_SVC_ENCODINGS;

            return config.SCREEN_SHARING_SIMULCAST_ENCODINGS;

        default: {
            break;
        }
    }
    return null;
}

