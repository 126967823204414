import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IQuiz } from '@tellsla/serverTypes';
import { isNil } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NoDataPlaceholder } from '../common';

const PREFIX = 'QuizPreview';

const classes = {
    root: `${PREFIX}-root`,
    textProps: `${PREFIX}-textProps`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        overflow: 'hidden',
        justifyContent: 'start',
        position: 'absolute',
    },

    [`& .${classes.textProps}`]: {
        alignSelf: 'normal',
        padding: theme.spacing(1),
        paddingTop: 0,
        paddingLeft: '36px',
    }
}));

interface IQuizPreviewProps {
    quiz: IQuiz;
}
export const QuizPreview: React.FC<IQuizPreviewProps> = ({ quiz }) => {

    const { t } = useTranslation();

    if (isNil(quiz)) return <NoDataPlaceholder>{t('No data available')}</NoDataPlaceholder>;

    return (
        <Root className={classes.root} data-testid='pedit-page-quiz-preview'>
            <Typography className={classes.textProps}>{quiz.title ?? ''}</Typography>
        </Root>
    );
}
