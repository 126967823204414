import { Button, DialogContentText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { isFunction, isString } from 'lodash';
import React, { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '@tellsla/config';
import { SmartButtonGroup } from './SmartButtonGroup';

interface IButtonDescription {
    id?: string;
    text: string;
    function: MouseEventHandler;
    icon: React.ReactElement;
}

export interface IUniversalModalAlertData {
    title?: string;
    content?: React.ReactElement | string;
    buttons?: Array<IButtonDescription>;
}

interface IUniversalModalAlertProps extends IUniversalModalAlertData {
    id?: string;
    isOpen: boolean;
    disableBackdropClick?: boolean;
    handleModalClose: () => void;
}

export function UniversalModalAlert({
    id,
    title = '',
    content = '',
    buttons = [],
    isOpen = false,
    disableBackdropClick = true,
    handleModalClose = () => {},
}: IUniversalModalAlertProps) {
    const navigate = useNavigate();

    // console.log('Rendering uni-modal-dialog', { id, title, content, isOpen });

    return (
        <Dialog
            id={id ?? 'universal-modal-alert'}
            open={isOpen}
            onClose={(event, reason) => {
                if (disableBackdropClick) {
                    if (reason !== 'backdropClick') {
                        handleModalClose(event, reason);
                    }
                } else if (isFunction(handleModalClose)) {
                    handleModalClose();
                } else {
                    navigate(config.frontendHost.fallbackPath);
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown={disableBackdropClick}>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {isString(content) ? (
                    <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
                ) : (
                    content
                )}
            </DialogContent>
            {buttons?.length > 0 && (
                <DialogActions>
                    {buttons?.length > 1 ? (
                        <SmartButtonGroup>
                            {buttons.map((button: IButtonDescription, index) => (
                                <Button
                                    id={button.id ?? button.text}
                                    key={button.text}
                                    onClick={button.function}
                                    startIcon={button.icon}
                                    variant={index === buttons.length - 1 ? 'contained' : undefined}>
                                    {button.text}
                                </Button>
                            ))}
                        </SmartButtonGroup>
                    ) : (
                        <Button
                            id={buttons[0].id ?? buttons[0].text}
                            key={buttons[0].text}
                            startIcon={buttons[0].icon}
                            onClick={buttons[0].function}
                            variant="contained">
                            {buttons[0].text}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}
