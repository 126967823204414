import { Typography, styled } from '@mui/material';
import React from 'react';

const Wrapper = styled('div')({
    height: '100%',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'start',
    position: 'absolute',
});

const QuizTitle = styled(Typography)(({ theme }) => ({
    alignSelf: 'normal',
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingLeft: '36px',
}));

interface IProps {
    quizTitle: string;
}

export const PlaybookSlideQuizPreview: React.FC<IProps> = ({ quizTitle }) => {
    return (
        <Wrapper data-testid='pedit-page-quiz-preview'>
            <QuizTitle>{quizTitle}</QuizTitle>
        </Wrapper>
    );
};
