/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';

import { useSnackbar } from 'notistack';

import NotifycationEmitter from './NotifycationEmitter';

import { MSG_UI_NOTIFICATION } from './notifyUtils';

/**
 * Компонент, слушающий события MSG_UI_NOTIFICATION и добавляющий в очередь уведомлений новое сообщение
 * @returns null
 */
export function NotificationListener() {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        function addMessageToQueue(message: any) {
            enqueueSnackbar(message?.message, message?.options);
        }
        NotifycationEmitter.on(MSG_UI_NOTIFICATION, addMessageToQueue);

        return () => {
            NotifycationEmitter.off(MSG_UI_NOTIFICATION, addMessageToQueue);
        };
    }, [enqueueSnackbar]);

    return null;
}
