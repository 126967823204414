import { IPlaybookPage } from "@tellsla/serverTypes";
import React from "react";

interface IContext {
    pageId?: string | null;
    pageIndex?: number | null;
    page?: IPlaybookPage | null;
    forcePreview: boolean;
    preloadFullMedia?: boolean;
}

export const PlaybookPageContext = React.createContext<IContext>({
    pageId: null,
    pageIndex: null,
    page: null,
    forcePreview: true,
    preloadFullMedia: false,
});