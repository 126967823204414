import { IOptions } from '../lib';
import { CLUB_ROOM, LOBBY_ROOM } from './room/constants';

export interface L2Table {
    id: string;
    overhear: {
        [key: string]: boolean;
    };
    options: IOptions;
}

export interface L2RoomExpositionItem {
    id: string;
    type: string;
}

export interface L2RoomExposition {
    items: {
        [key: string]: L2RoomExpositionItem;
    };
    itemList: Array<string>;
    pinnedItems: {
        [key: string]: string;
    };
    options: IOptions;
}

export interface L2Room {
    id: string;
    expo: L2RoomExposition;
    tableList: Array<string>;
    tables: {
        [tableId: string]: L2Table;
    };
}

export interface L2Layout {
    roomList: Array<L2Room>;
    rooms: {
        [roomId: string]: L2Room;
    };
    options: IOptions;
}
export const defaultL2Layout: L2Layout = {
    roomList: [CLUB_ROOM.id, LOBBY_ROOM.id],
    rooms: {
        [CLUB_ROOM.id]: CLUB_ROOM,
        [LOBBY_ROOM.id]: LOBBY_ROOM,
    },
    options: {},
};

export interface IL2GetterOptions {
    createIfNotExists?: boolean;
}
