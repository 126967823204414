import React from 'react';

type DragEventHandler = (e: React.DragEvent) => void;

interface ISharedDndContext {

    onDrop?: DragEventHandler,
    onDragOver: DragEventHandler,
    onDragEnter: DragEventHandler,
    onDragLeave: DragEventHandler,
    onDragStart: DragEventHandler,
    onDragEnd: DragEventHandler,
    clearDroppedFiles: () => void,
    droppedFiles: Array<File>,
}

export const SharedDndContext = React.createContext<ISharedDndContext>({
    // onDrop: (e) => {},
    onDragOver: () => {},
    onDragEnter: () => {},
    onDragLeave: () => {},
    onDragStart: () => {},
    onDragEnd: () => {},
    clearDroppedFiles: () => {},
    droppedFiles: []
});