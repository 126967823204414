import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
} from "@mui/material";
import parse from 'html-react-parser';
import React from "react";
import { useTranslation } from "react-i18next";

export const LicenseLink = ({ text }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Link onClick={handleClickOpen}>{t('License Agreement')}</Link>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title"><b>{t('User License Agreement')}</b></DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        component={'div'}
                        tabIndex={-1}
                    >
                        <div className="file-content-box">{parse(text)}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Close')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}