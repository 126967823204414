import React from 'react';

export interface IVideoPlayerContext {
    videoElementId: string;
    sources: Array<{ url: string; mimetype: string }>;
    videoRef: any;
    videoStartTime: number;
    videoEndTime: number;
    videoIsLooping: boolean;
    videoIsMuted: boolean;
    videoAutoplay: boolean;
}

export const VideoPlayerContext = React.createContext<IVideoPlayerContext>({
    videoElementId: '{page-id}-video-player',
    sources: [],
    videoRef: null,
    videoStartTime: 0,
    videoEndTime: -1,
    videoIsLooping: false,
    videoIsMuted: false,
    videoAutoplay: false,
});
