import { DeepPartial } from './common';
import {
    EventRunTimer,
    EventRunTimerActionType,
    EventRunTimerCommand,
    EventRunTimerCountDirection,
    EventRunTimerStatus,
    EventRunTimerType,
    EventRunTimerVisibility,
    IEventRunPageTimer,
    IEventRunTimer,
    TimerEffectId,
} from './eventRunTimer';

export const defaultEventRunGeneralTimerId = EventRunTimerType.General;
export const defaultEventRunSessionTimerId = EventRunTimerType.SessionScheduler;
export const defaultEventRunLongTimerFormat = 'H:mm:ss';
export const defaultEventRunShortTimerFormat = 'm:ss';
export const defaultEventRunSesstionShortTimerFormat = 'H[h] mm[m]';
export const defaultEventRunSesstionLongTimerFormat = 'H[h] mm[m]';

export const defaultEventRunGeneralTimer: IEventRunTimer = {
    id: defaultEventRunGeneralTimerId,

    type: EventRunTimerType.General,

    tickCount: 0,
    visibility: EventRunTimerVisibility.NONE,
    duration: 300,
    status: EventRunTimerStatus.IDLE,

    params: {
        displayFormat: {
            short: defaultEventRunShortTimerFormat,
            long: defaultEventRunLongTimerFormat,
        },
        minValue: 0,
        maxValue: 300,
        direction: EventRunTimerCountDirection.DOWN,
        on: {
            [TimerEffectId.TimerStart]: [{ id: 'START', type: EventRunTimerActionType.BEEP_ONCE }],
            [TimerEffectId.MidTime]: [
                { id: 'MIDTIME', type: EventRunTimerActionType.MESSAGE, data: { message: 'Half time' } },
            ],
            [TimerEffectId.BeforeTimerFinish]: [
                {
                    id: 'BEFORE_FINISH',
                    type: EventRunTimerActionType.MESSAGE,
                    data: { message: '1 minute to finish', seconds: 60 },
                },
            ],
            [TimerEffectId.TimerFinish]: [{ id: 'FINISH', type: EventRunTimerActionType.BEEP_DOUBLE }],
        },
    },
    lastRunTimeStamp: -1,
    lastRunTickCount: 0,
};

export const defaultEventRunSessionTimer: IEventRunTimer = {
    id: defaultEventRunSessionTimerId,
    type: EventRunTimerType.SessionScheduler,
    tickCount: 0,
    params: {
        displayFormat: {
            short: defaultEventRunSesstionShortTimerFormat,
            long: defaultEventRunSesstionLongTimerFormat,
        },
        minValue: 0,
        maxValue: -1,
        direction: EventRunTimerCountDirection.UP,
        on: {
            [TimerEffectId.TimerStart]: [
                { id: 'START', type: EventRunTimerActionType.MESSAGE, data: { message: 'Session started' } },
            ],
            [TimerEffectId.Every]: [
                {
                    id: 'EVERY_1_HOUR',
                    type: EventRunTimerActionType.MESSAGE,
                    data: { message: 'An hour passed', seconds: 3600 },
                },
            ],
        },
    },
    visibility: EventRunTimerVisibility.PERSONAL,
    status: EventRunTimerStatus.IDLE,
    duration: -1,
    lastRunTimeStamp: -1,
    lastRunTickCount: 0,
};

export const defaultEventRunPageTimer: IEventRunPageTimer = {
    id: defaultEventRunGeneralTimerId,
    type: EventRunTimerType.CurrentPage,
    tickCount: 0,
    params: {
        displayFormat: {
            short: defaultEventRunShortTimerFormat,
            long: defaultEventRunLongTimerFormat,
        },
        minValue: 0,
        maxValue: 300,
        direction: EventRunTimerCountDirection.DOWN,
        on: {
            [TimerEffectId.MidTime]: [
                { id: 'MIDTIME', type: EventRunTimerActionType.MESSAGE, data: { message: 'Half time' } },
            ],
            [TimerEffectId.TimerStart]: [{ id: 'START', type: EventRunTimerActionType.BEEP_ONCE }], // action ID to execute
        },
    },
    visibility: EventRunTimerVisibility.GLOBAL,
    onPage: {
        enter: [
            { id: 'PAGE_ENTER', type: EventRunTimerActionType.BEEP_ONCE },
            {
                id: 'PAGE_ENTER_MESSAGE',
                type: EventRunTimerActionType.MESSAGE,
                data: { message: 'Timer started' },
            },
        ],
        exit: [],
    },
    status: EventRunTimerStatus.IDLE,
    duration: 300,
    lastRunTimeStamp: -1,
    lastRunTickCount: 0,
};

export function timerGetDefaultTimers(): Record<string, EventRunTimer> {
    return {
        [defaultEventRunSessionTimerId]: new EventRunTimer(defaultEventRunSessionTimer),
        [defaultEventRunGeneralTimerId]: new EventRunTimer(defaultEventRunGeneralTimer),
    };
}
export function timerGetDefaultTimersData(): Record<string, IEventRunTimer> {
    return {
        [defaultEventRunSessionTimerId]: defaultEventRunSessionTimer,
        [defaultEventRunGeneralTimerId]: defaultEventRunGeneralTimer,
    };
}

export interface IWSCommandsTimerCommandData {
    timerId: string;
    timerData?: DeepPartial<IEventRunPageTimer>;
    timerCommand?: EventRunTimerCommand;
    timerEvent?: EventRunTimerCommand;
}
