import { Box, TableCell, TableCellProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const PREFIX = 'QuizCommons';

const classes = {
    quizTotalsKVLineTitle: `${PREFIX}-quizTotalsKVLineTitle`,
    quizTotalsKVLineValue: `${PREFIX}-quizTotalsKVLineValue`,
};

const StyledBox = styled(Box)(({ theme }) => ({
    [`& .${classes.quizTotalsKVLineTitle}`]: {
        marginRight: theme.spacing(1),
    },

    [`& .${classes.quizTotalsKVLineValue}`]: {
        alignSelf: 'flex-end',
    },
}));

interface IParitallyFilledTableCellProps extends TableCellProps {
    percent: unknown;
    gradientColorStart: string;
    gradientColorEnd: string;
}

export const ParitallyFilledTableCell: React.FC<IParitallyFilledTableCellProps> = ({
    percent,
    padding = 'none',
    align = 'center',
    gradientColorStart = '#f0f0f0',
    gradientColorEnd = '#b0b0b0',
    children,
}) => {
    return (
        <TableCell
            align={align}
            padding={padding}
            size="small"
            style={{
                backgroundImage: `linear-gradient(${gradientColorStart},${gradientColorEnd})`,
                backgroundSize: `96% ${percent}%`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom',
            }}>
            {children}
        </TableCell>
    );
};

interface IParitallyFilledBoxProps extends React.CSSProperties {
    percent: unknown;
    barColor: string;
    style: React.CSSProperties;
    barDir?: 'horizontal' | 'vertical';
    children?: React.ReactNode;
}

export const ParitallyFilledBox: React.FC<IParitallyFilledBoxProps> = ({
    percent,
    barDir = 'horizontal',
    width = '24px',
    padding = 'none',
    // align = 'center',
    barColor = '#b0b0b0',
    backgroundColor = '#f0f0f0',
    style,
    children,
}) => {
    const isVertical = barDir === 'vertical';
    return (
        <StyledBox
            // align={align}
            padding={padding}
            width={isVertical ? width : '100%'}
            height={isVertical ? '100%' : width}
            style={{
                backgroundImage: `linear-gradient(${
                    isVertical ? 'to bottom' : 'to left'
                }, ${barColor},${barColor})`,
                backgroundSize: isVertical ? `99% ${percent}%` : `${percent}% 99%`,
                borderLeft: `1px solid ${barColor}`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: isVertical ? 'bottom' : 'left',
                backgroundColor,
                ...style,
            }}>
            {children}
        </StyledBox>
    );
};

export function KeyValueLine({ title, value }) {
    return (
        <Box display="flex" justifyContent="space-between">
            <Typography className={classes.quizTotalsKVLineTitle}>{`${title}:`}</Typography>
            &nbsp;
            <Typography className={classes.quizTotalsKVLineValue}>{value}</Typography>
        </Box>
    );
}
KeyValueLine.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
};

export function TabPanel(props) {
    const { children, value, index, sx, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`quizcc-tabpanel-${index}`}
            aria-labelledby={`quizcc-tab-${index}`}
            sx={{
                ...sx,
                maxWidth: '100%',
                width: '100%',
                height: '100%',
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}>
            {value === index && <Box sx={{ p: 3, height: '100%', width: '100%' }}>{children}</Box>}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    sx: PropTypes.object,
};

export function a11yProps(index) {
    return {
        id: `quizcc-tab-${index}`,
        'aria-controls': `quizcc-tabpanel-${index}`,
    };
}
